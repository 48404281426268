import React, { useState, ChangeEvent, useEffect, useMemo } from 'react';
import { useItemsStore } from "../../../contexts/zustand/onboarding"
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Grid, Box, Typography, TextField, Button } from '@mui/material';

type ValidationProduct = {
    id: number;
    name: string;
    price: string;
    description: string;
}

type TextInputFieldProps = {
    refKey: string;
    valueLabel: string;
    setValueLabel: (value: string) => void;
};

type TextRequiredProps = {
    refKey: string;
    valueRequired: boolean;
    setValueRequired: (value: boolean) => void;
};

type TextDescriptionProps = {
    refKey: string;
    valueDescription: string;
    setValueDescription: (value: string) => void;
};

const keyValidations: { [key: string]: string } = {
    '20': 'selfie',
    '21': 'cnh'
}

const textFieldStyles = {
    marginRight: '5px',
    flex: 1,
    borderColor: '#9454A4',
    borderRadius: '5px',
    '&:hover': { border: 'none' }
};

const buttonStyles = {
    minWidth: 'auto',
    padding: '5px',
};

const iconSize = '2em';

export const TextComponentField = () => {
    return (
        <Grid sx={{ padding: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Typography sx={{ fontSize: '25px' }}>Propriedades do campo</Typography>
        </Grid>
    )
}

export const TextInputField = ({ refKey, valueLabel, setValueLabel }: TextInputFieldProps) => {

    const { items } = useItemsStore()
    const [isInitialized, setIsInitialized] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const currentItem = items.find(item => item.key === refKey);

    if (!isInitialized && currentItem?.label) {
        setValueLabel(currentItem.label);
        setIsInitialized(true);
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        if (value.length === currentItem?.length_label!) {
            setError(`Você atingiu o limite máximo de ${currentItem?.length_label!} caracteres.`);
            setTimeout(() => {
                setError('');
            }, 3000);
        } else {
            setError('');
        }

        setValueLabel(value);
    };

    return (
        <Box sx={{ width: '100%', marginBottom: '10px' }}>
            <Typography sx={{ color: '#9454A4' }}>Nome do campo:</Typography>
            <TextField
                value={valueLabel ?? currentItem?.label ?? ''}
                onChange={handleChange}
                sx={textFieldStyles}
                fullWidth
                variant="outlined"
                margin="normal"
                error={!!error}
                helperText={error}
                inputProps={{
                    maxLength: currentItem?.length_label,
                }}
            />
        </Box>
    )
}

const OptionsField = ({ refKey }: { refKey: string }) => {
    const { items, optionsValues, setOptionsValues } = useItemsStore();
    const [newOption, setNewOption] = useState<string>('');
    const [error, setError] = useState<string>('');
    const currentItem = items.find(item => item.key === refKey);
    const initialOptions = useMemo(() => currentItem?.options ?? [], [currentItem?.options]);

    useEffect(() => {
        if (initialOptions.length > 0 && !optionsValues[refKey]) {
            setOptionsValues(refKey, initialOptions);
        }
    }, [initialOptions, optionsValues, refKey, setOptionsValues]);

    const handleNewOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        setError(inputValue.length === currentItem?.length_label ? `Você atingiu o limite máximo de ${currentItem?.length_label} caracteres.` : '');
        setNewOption(inputValue);
    };

    const addOption = () => {
        if (newOption.trim() === "") {
            setError('Adicione uma opção');
            return;
        }
        if (!(optionsValues[refKey] || []).includes(newOption.trim())) {
            setOptionsValues(refKey, [...(optionsValues[refKey] || []), newOption.trim()]);
            setNewOption('');
            setError('');
        } else {
            setError('Opção já existe.');
        }
    };

    const removeOption = (index: number) => {
        const newOptions = optionsValues[refKey].filter((_, i) => i !== index);
        setOptionsValues(refKey, newOptions);
    };

    const handleOptionChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const newOptions = [...(optionsValues[refKey] || [])];
        newOptions[index] = event.target.value;
        setOptionsValues(refKey, newOptions);
    };

    return (
        <Box sx={{ width: '100%', marginBottom: '10px' }}>
            <Typography sx={{ color: '#9454A4', marginBottom: '5px' }}>Opções:</Typography>
            {(optionsValues[refKey] || initialOptions).map((option, index) => (
                <Box key={`option-${index}`} sx={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
                    <TextField
                        value={option}
                        onChange={(e) => handleOptionChange(e, index)}
                        sx={{ flex: 1, marginRight: '10px' }}
                        size="small"
                        variant="outlined"
                        placeholder="Digite uma opção"
                    />
                    <Button onClick={() => removeOption(index)} sx={buttonStyles}>
                        <RemoveCircleOutlineIcon sx={{ color: 'red', fontSize: iconSize }} />
                    </Button>
                </Box>
            ))}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
                <TextField
                    value={newOption}
                    onChange={handleNewOptionChange}
                    sx={{ flex: 1, marginRight: '10px' }}
                    size="small"
                    variant="outlined"
                    placeholder="Adicionar nova opção"
                    error={!!error}
                    helperText={error}
                    inputProps={{ maxLength: currentItem?.length_label }}
                />
                <Button onClick={addOption} sx={buttonStyles}>
                    <AddCircleOutlineIcon sx={{ color: 'green', fontSize: iconSize }} />
                </Button>
            </Box>
        </Box>
    );
};

export const SelectOptions = (props: { refKey: string }) => <OptionsField {...props} />;
export const RadioOptions = (props: { refKey: string }) => <OptionsField {...props} />;
export const CheckBoxOptions = (props: { refKey: string }) => <OptionsField {...props} />;

export const OptionRequired = ({ refKey, valueRequired, setValueRequired }: TextRequiredProps) => {

    const { items } = useItemsStore();

    const currentItem = items.find(item => item.key === refKey);
    const initialChecked = currentItem?.required;

    useEffect(() => {
        if (refKey) {
            setValueRequired(initialChecked === true ? true : false);
        }
    }, [refKey, initialChecked, setValueRequired]);


    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (refKey) {
            setValueRequired(e.target.checked);
        }
    };

    const isChecked = refKey ? (valueRequired !== undefined ? valueRequired : currentItem?.required) : false;

    return (

        <FormControlLabel
            control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} />}
            label="Este campo é obrigatório:"
        />

    )
}

export const OptionsValidations = ({ refKey }: { refKey: string }) => {

    const { items, validations, toggleRemoveValidation, validationsOptions } = useItemsStore();

    const fieldValidations = ['20', '21']
    const filteredItems = items.filter((item) => fieldValidations.includes(item.type!));

    const currentItem = filteredItems.find(item => item.key === refKey);

    const itemValidation = keyValidations[currentItem?.type!]

    return (
        <>
            {validationsOptions[itemValidation] && (
                <>
                    <Grid sx={{ padding: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography sx={{ fontSize: '25px' }}>Validações</Typography>
                    </Grid>

                    <Grid
                        container
                        gap={2}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                        {(validationsOptions[itemValidation] as ValidationProduct[]).map((item: ValidationProduct) => (
                            <Box
                                key={item.id}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    borderBottom: '1px solid red',
                                    paddingBottom: 2,
                                    marginBottom: 2,
                                }}>

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                Array.isArray(validations[refKey]) &&
                                                validations[refKey].some((v: string) => v === String(item.id))
                                            }
                                            defaultChecked
                                            onChange={
                                                (e) => toggleRemoveValidation(currentItem?.type!, String(item.id), e?.target.checked, refKey)
                                            }
                                        />
                                    }
                                    label={item.name}
                                />
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography><strong>Descrição:</strong> {item.description}</Typography>

                                    <Typography sx={{ marginTop: '8px' }}><strong>Preço:</strong> {item.price}</Typography>
                                </Box>
                            </Box>

                        ))}
                    </Grid>
                </>
            )}
        </>
    );
};

export const TextAreaDescription = ({ refKey, valueDescription, setValueDescription }: TextDescriptionProps) => {

    const { items } = useItemsStore()
    const [isInitialized, setIsInitialized] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const currentItem = items.find(item => item.key === refKey);

    useEffect(() => {
        if (!isInitialized && currentItem?.description) {
            setValueDescription(currentItem.description!);
            setIsInitialized(true);
        }
    }, [isInitialized, currentItem, setValueDescription]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        if (value.length === 500!) {
            setError(`Você atingiu o limite máximo de 500 caracteres.`);
            setTimeout(() => {
                setError('');
            }, 3000);
        } else {
            setError('');
        }

        setValueDescription(value);
    };

    const currentLength = valueDescription.length ?? currentItem?.description?.length ?? 0;

    return (
        <Box
            sx={{ marginBottom: '10px', marginTop: '10px' }}
        >
            <Typography sx={{ color: '#9454A4' }}>Descrição do campo:</Typography>
            <TextField
                id="filled-multiline-static"
                label="Descrição"
                value={valueDescription ?? currentItem?.description ?? ''}
                onChange={handleChange}
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                error={!!error}
                helperText={error || `${currentLength}/500 caracteres`}
                inputProps={{
                    maxLength: 500,
                }}
                sx={{
                    marginTop: '8px',
                    '& .MuiFilledInput-root': {
                        backgroundColor: '#F5F5F5', 
                        border: '2px solid black', 
                        borderRadius: '4px', 
                    },
                    '& .MuiFilledInput-root:hover': {
                        border: '2px solid black',
                    },
                }}
            />
        </Box>
    )
}

export const ButtonSavedValues = ({ setOpen, onSubmit }: any) => {
    const handleSave = () => {
        onSubmit(); // Chama o onSubmit da `EditFieldQuestion`
    };

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <Button
                sx={{
                    backgroundColor: '#9454A4', color: '#FFFF',
                    '&:hover': {
                        backgroundColor: '#9454A4',
                        color: 'white'
                    },
                    width: '20%', height: '60px', borderRadius: '4px', marginTop: '10%'
                }}
                onClick={handleSave}>
                SALVAR
            </Button>
        </Box>
    );
};



