import AppRoutes from './routes'
import GlobalStyles from './styles/global'
import { AuthProvider } from './contexts/authContext'
import 'react-toastify/dist/ReactToastify.min.css'

function App() {
  return (
    <>
      <GlobalStyles />
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </>
  );
}

export default App