import { create } from 'zustand';
import { v4 as uuidv4 } from "uuid";

const PRODUCT_ID_OCR_DOCUMENTOS = process.env.REACT_APP_PRODUCT_ID_OCR_DOCUMENTOS
const PRODUCT_ID_OCR_CNH_SERPRO = process.env.REACT_APP_PRODUCT_ID_OCR_CNH_SERPRO
const PRODUCT_ID_CNH_SERPRO = process.env.REACT_APP_PRODUCT_ID_CNH_SERPRO

const keysValidations: { [key: string]: string } = {
    '20': 'selfie',
    '21': 'cnh',
    'input': 'input'
}

export type DraggableItem = {
    id?: string;
    key?: string;
    id_choice?: string;
    type?: string;
    label?: string;
    required?: boolean;
    error?: string;
    options?: string[];
    description?: string;
    validations?: string[];
    length_label?: number;
    length_value?: number;
    price?: number;
    onDragEnd?: (item: DraggableItem) => void;
}

type ValidationItem = {
    id: number;
    name: string;
    price: string;
    description: string;
};

type ValidationResult = Record<string, ValidationItem[]>;

type FormState = {
    fileProcessing: boolean;
    items: DraggableItem[];
    basePrice: number;
    totalPrice: number;
    optionsValues: Record<string, string[]>;
    validations: Record<string, string[]>;
    validationsOptions: Record<string, ValidationItem[]>;
    priceValidations: Record<string, number>;
    addItems: (item: DraggableItem) => void;
    calculatePrice: () => void;
    removeItems: (key: string, remove: string, validations: any) => void;
    updateItems: (items: DraggableItem[], saved?: boolean) => void;
    setOptionsValues: (key: string, options: string[]) => void;
    clearFormValues: () => void;
    setFileProcessing: (newState: boolean) => void;
    toggleValidation: (validationResult: ValidationResult) => void;
    toggleAddValidation: (type: string, typeToRemove: string | boolean, key?: string) => void;
    updateValidation: (key: string, type: string, idsValidations: string[]) => void;
    toggleRemoveValidation: (type: string, idValidation: string | boolean, addOrRemoveValidation: boolean, key: string) => void;
}

export const useItemsStore = create<FormState>((set, get) => ({
    fileProcessing: false,
    items: [],
    basePrice: 2.8,
    totalPrice: 2.8,
    optionsValues: {},
    validations: {},
    priceValidations: {
        input: 0,
        field: 0
    },
    validationsOptions: {},
    addItems: (item) => {
        const keyRandom = uuidv4();
        set((state) => ({
            items: [
                ...state.items,
                {
                    id: item.id,
                    key: keyRandom,
                    id_choice: item.id_choice,
                    type: item.type,
                    label: item.label,
                    required: item.required || false,
                    error: item.error,
                    options: item.options || [],
                    validations: item.validations,
                    length_label: item.length_label,
                    length_value: item.length_value,
                    price: item.price,
                },
            ],
        }));

        if (item.validations) {
            const type = item.type!;
            get().toggleAddValidation(type, false, keyRandom);
        } else {
            get().calculatePrice()
        }
    },

    updateItems: (updatedItems, saved) => set((state) => {
        const newState = {
            items: updatedItems,
        };
        
        if (saved) {
            updatedItems.forEach((item) => {
                if (Array.isArray(item.validations) && item.validations!.length > 0) {
                    get().updateValidation(item.key!, item.type!, item.validations!);
                }
            });
        }

        setTimeout(() => {
            get().calculatePrice();
        }, 200)

        return newState;
    }),

    removeItems: (key, type, validation) => {
        set((state) => ({
            items: state.items.filter((item) => item.key !== key),
        }));

        if (type === '20' || type === '21') {
            const hasValidation = validation === true || (Array.isArray(validation) && validation.length > 0);
            hasValidation ? get().toggleAddValidation(type, true, key) : get().calculatePrice();
        } else {
            get().calculatePrice();
        }
    },

    calculatePrice: () => {
        const { items, basePrice, priceValidations } = get();

        const choicesTextFields = ['0', '3', '4', '5', '6']
        const ChoicesAttachmentsFields = ['1', '2']

        const textFields = items.filter(
            (item) => choicesTextFields.includes(item.id_choice!)
        );

        const attachments = items.filter(
            (item) => ChoicesAttachmentsFields.includes(item.id_choice!)
        );

        const textFieldsExcess = textFields.length > 15 ? textFields.slice(15) : [];

        const filteredAttachments = attachments.filter((item) => item.type !== "19");

        const attachmentsExcess = filteredAttachments.length > 5 ? filteredAttachments.slice(5) : [];

        const additionalTextPrice = textFieldsExcess.reduce((acc, item) => acc + item.price!, 0);
        const additionalAttachmentsPrice = attachmentsExcess.reduce((acc, item) => acc + item.price!, 0);

        const signaturePrice = attachments
            .filter((item) => item.type === "19")
            .reduce((acc, item) => acc + item.price!, 0);

        const newTotalPrice =
            basePrice + additionalTextPrice + additionalAttachmentsPrice + signaturePrice;

        const totalPrices = newTotalPrice + priceValidations['input'] + priceValidations['field'];

        set({ totalPrice: totalPrices });
    },

    setOptionsValues: (key, options) => set((state) => ({
        optionsValues: { ...state.optionsValues, [key]: options },
    })),

    toggleValidation: (validation) =>
        set((state) => ({
            validationsOptions: {
                ...state.validationsOptions,
                ...validation,
            },
        })),

    toggleAddValidation: (type, typeToRemove, key) => set((state) => {

        const keyValitadion = keysValidations[type]
        const validations = state.validationsOptions[keyValitadion]

        const ChoicesAttachmentsFields = ['1', '2']

        const newEntry: Record<string, string[]> = {
            [key!]: validations.map(item => String(item.id))
        };

        state.validations = {
            ...state.validations,
            ...newEntry
        };

        const attachments = state.items.filter(
            (item) => ChoicesAttachmentsFields.includes(item.id_choice!)
        );

        const filteredAttachments = attachments.filter((item) => item.type !== "19");

        const totalPriceToAdd = validations.reduce((acc, item) => {
            const price = parseFloat(item.price.replace(",", "."));
            return acc + price;
        }, 0);


        if (typeToRemove) {
            const updateValue = state.basePrice > state.totalPrice - totalPriceToAdd ? state.basePrice : (state.totalPrice - totalPriceToAdd) - (filteredAttachments.length >= 4 ? 0.10 : 0)

            state.priceValidations = {
                ...state.priceValidations,
                field: state.priceValidations.field - totalPriceToAdd
            };

            return {
                ...state,
                totalPrice: updateValue
            };
        }

        state.priceValidations = {
            ...state.priceValidations,
            field: state.priceValidations.field + totalPriceToAdd
        };

        return {
            ...state,
            totalPrice: (state.totalPrice + totalPriceToAdd) + (filteredAttachments.length >= 5 ? 0.10 : 0),
        };
    }),

    updateValidation: (keyValidation, itemType, idsValidations) => set((state) => {

        const newValidations = {
            ...state.validations,
            [keyValidation]: idsValidations,
        };

        let keyPriceValidations: string = ''

        keyPriceValidations = keyValidation

        if (itemType) keyPriceValidations = keysValidations[itemType]

        const keyPrice = itemType === '20' || itemType === '21' ? 'field' : 'input';

        const totalPriceToAdd = idsValidations.reduce((total, idValidation) => {
            const validationOptions = state.validationsOptions[keyPriceValidations];

            if (Array.isArray(validationOptions)) {
                const priceToAdd = validationOptions
                    .find(item => String(item.id) === String(idValidation))?.price;

                if (priceToAdd) {
                    const priceToAddValue = parseFloat(priceToAdd.replace(",", "."));
                    return total + priceToAddValue;
                }
            }

            return total;
        }, 0);

        const updatedPriceValidations = {
            ...state.priceValidations,
            [keyPrice]: (state.priceValidations[keyPrice] || 0) + totalPriceToAdd,
        };

        return {
            ...state,
            validations: newValidations,
            priceValidations: updatedPriceValidations
        };
    }),

    toggleRemoveValidation: (type, idValidation, addOrRemoveValidation, key) => set((state) => {

        const keyValitadion = keysValidations[type]
        const validations = state.validationsOptions[keyValitadion]

        const keyPriceValidations = key === 'input' ? 'input' : 'field'


        if (idValidation) {
            if (!state.validations[key!]) {
                state.validations[key!] = [];
            }

            const validationCNH = idValidation === PRODUCT_ID_CNH_SERPRO && !state.validations[key!].includes(String(PRODUCT_ID_OCR_DOCUMENTOS))

            if (addOrRemoveValidation) {
                if (!state.validations[key!].includes(String(idValidation))) {
                    if (validationCNH) {
                        state.validations[key!].push(String(idValidation));
                        state.validations[key!].push(String(PRODUCT_ID_OCR_CNH_SERPRO));
                    } else {
                        state.validations[key!].push(String(idValidation));
                    }
                }

                const priceToAdd = validations.find(item => String(item.id) === idValidation)?.price!;
                const priceToAddValue = parseFloat(priceToAdd.replace(",", "."));

                state.priceValidations = {
                    ...state.priceValidations,
                    [keyPriceValidations]: state.priceValidations[keyPriceValidations] + priceToAddValue
                }

                return {
                    ...state,
                    totalPrice: state.totalPrice + priceToAddValue
                };

            } else {

                const validationCNH = idValidation === PRODUCT_ID_CNH_SERPRO && state.validations[key!].includes(String(PRODUCT_ID_OCR_CNH_SERPRO))

                if (validationCNH) {
                    state.validations[key!] = state.validations[key!].filter(id => id !== PRODUCT_ID_OCR_CNH_SERPRO);
                }

                state.validations[key!] = state.validations[key!].filter(id => id !== idValidation);

                if (state.validations[key!].length === 0) {
                    delete state.validations[key!];
                }

                const priceToRemove = validations.find(item => String(item.id) === idValidation)?.price!;

                const priceToRemoveValue = parseFloat(priceToRemove.replace(",", "."));

                const updateValue = state.basePrice > state.totalPrice - priceToRemoveValue ? state.basePrice : state.totalPrice - priceToRemoveValue

                state.priceValidations = {
                    ...state.priceValidations,
                    [keyPriceValidations]: state.priceValidations[keyPriceValidations] - priceToRemoveValue
                }

                return {
                    ...state,
                    totalPrice: updateValue
                };
            }
        }

        return state
    }),

    clearFormValues: () => set(() => ({
        items: [],
        optionsValues: {},
        validations: {},
        validationsOptions: {},
        validationsInput: [],
        totalPrice: 2.80,
        priceValidations: {
            input: 0,
            field: 0
        },
    })),

    setFileProcessing: (newState) => set(() => ({
        fileProcessing: newState,
    })),
}));
