/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from 'formik'
import { Methods } from './methods'
import Grid from '@mui/material/Grid'
import List from "@mui/material/List"
import { toast } from 'react-toastify'
import { Categories } from "./categories"
import Button from "@mui/material/Button"
import Avatar from "@mui/material/Avatar"
import * as api from '../../../services/api'
import ListItem from "@mui/material/ListItem"
import MenuItem from '@mui/material/MenuItem'
import { RequestTypes } from "./request_types"
import { ToastContainer } from "react-toastify"
import TextField from '@mui/material/TextField'
import { useCore } from "../../../hooks/useCore"
import { ResponseTypes } from './response_types'
import InputLabel from '@mui/material/InputLabel'
import IconButton from "@mui/material/IconButton"
import { LooseObject } from "../../../types/core"
import FormControl from '@mui/material/FormControl'
import DeleteIcon from '@mui/icons-material/Delete'
import ListItemText from "@mui/material/ListItemText"
import { LightMode } from "../../../styles/lightMode"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import { useLocation, useParams } from "react-router-dom"
import { ParameterProps } from "../../../types/parameter"
import { dysplayValueType, ValuesTypes } from "./value_type"
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { dysplayLocal, LocalParameter } from "./local_parameters"
import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { ApiProps, ParameterDescription, RetrieveApiProps } from '../../../types/api'
import { Alert, Autocomplete, Box, FormControlLabel, FormGroup, FormHelperText, ListItemButton, ListItemSecondaryAction, Modal, Switch, Typography } from "@mui/material"

interface ModalInfoProps {
  open: boolean
  item: ParameterProps | undefined
}

export default function CreateOrUpdateApi(): JSX.Element {
  const location: LooseObject = useLocation()
  let { id, provider_id } = useParams()
  const { setTitleBar, setPathTitleBar } = useCore()

  // Dados a API
  const [urlTokenApis, setUrlTokenApis] = useState<string>()
  const [category, setCategory] = useState<string>("1")
  const [requestType, setRequestType] = useState<string>("1")
  const [method, setMethod] = useState<string>("1")
  const [responseType, setResponseType] = useState<string>("1")
  const [dynamicToken, setDynamicToken] = useState<boolean>(false)
  const [parameters, setParameters] = useState<ParameterProps[]>([])
  const [listParameterDescription, setListParameterDescription] = useState<ParameterDescription[]>([])
  const [dynamicsTokens, setDynamicsTokens] = useState<ApiProps[]>([])
  // Dados dos parametros da API
  const [localParameter, setLocalParameter] = useState<string>("1")
  const [fixedParameter, setFixedParameter] = useState<string>("0")
  const [valueTypeParameter, setValueTypeParameter] = useState<"1" | "2" | "3" | "4" | "5" | "6">("1")
  const [required, setRequired] = useState<string>("1")
  const [openInfo, setOpenInfo] = useState<number>(-1)

  const { handleChange, handleSubmit, values, errors, setFieldValue, touched } = useFormik({
    initialValues: {
      url: '',
      name: '',
      webhook: false,
      description: '',
      jsonstructure: '',
      boolean_value: '',
      parameter_name: '',
      parameter_value: '',
      boolean_default: '',
      dynamic_token_id: '',
      parameter_description: '',
    },
    onSubmit: createOrUpdateApi,
  })

  // API function
  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string)
    if (!id) {
      setDynamicToken(false)
      setFieldValue('dynamic_token_id', '')
    }
  }

  const handleResquestTypeChange = (event: SelectChangeEvent) => {
    setRequestType(event.target.value as string)
  }

  const handleMethodChange = (event: SelectChangeEvent) => {
    setMethod(event.target.value as string)
  }

  const handleResponseTypeChange = (event: SelectChangeEvent) => {
    setResponseType(event.target.value as string)
  }

  // Parameters function
  async function getDescriptionParameters() {
    await api.get('api/list_description_api/').then((response: any) => {
      setListParameterDescription(response.content.results)
    })
  }

  const handleLocalParameterChange = (event: SelectChangeEvent) => {
    setLocalParameter(event.target.value as string)
  }

  const handleFixedParameterChange = (event: SelectChangeEvent) => {
    setFixedParameter(event.target.value as string)
    setFieldValue("parameter_value", "")
  }

  const handleValueTypeParameterChange = (event: SelectChangeEvent) => {
    setValueTypeParameter(event.target.value as "1" | "2" | "3" | "4" | "5" | "6")
    if (['3', '4', '5', '6'].indexOf(event.target.value) > -1) {
      if (event.target.value === '3') {
        setRequired('0')
      } else {
        setFieldValue('boolean_default', '')
        setFieldValue('boolean_value', '')
      }
      setFixedParameter('0')
      setFieldValue('parameter_value', '')
    }
  }

  const handleRequiredChange = (event: SelectChangeEvent) => {
    setRequired(event.target.value as string)
  }

  // Page functions
  const addParameter = async () => {
    if (values.parameter_name.length === 0) {
      return toast.error("CUIDADO! Não adicione parâmetros vazios!", {
        position: toast.POSITION.TOP_RIGHT
      })
    }

    if (values.parameter_name.includes(' ')) {
      return toast.error("CUIDADO! Não adicione espaços no campo parâmetro!", {
        position: toast.POSITION.TOP_RIGHT
      })
    }

    if (values.parameter_description.length === 0) {
      return toast.error("ATENÇÃO! Você precisa adicionar uma descrição para seu cliente.", {
        position: toast.POSITION.TOP_RIGHT
      })
    }

    if (['1', '2'].indexOf(valueTypeParameter) > -1) {
      if (fixedParameter === "1" && values.parameter_value.length === 0) {
        return toast.error("CUIDADO! Você precisa adicionar um valor válido para parâmetros fixados! (De acordo com tipo de valor escolhido)", {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    }

    if (valueTypeParameter === '3') {
      if (values.boolean_default.length === 0) {
        return toast.error("CUIDADO! Você precisa adicionar uma valor padrão para parâmetros do tipo booleano!", {
          position: toast.POSITION.TOP_RIGHT
        })
      }

      if (values.boolean_value.length === 0) {
        return toast.error("CUIDADO! Você precisa adicionar uma valor se marcado para parâmetros do tipo booleano!", {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    }

    let exist = false

    parameters.forEach((item) => {
      if (item.name === values.parameter_name) {
        exist = true
      }
    })

    if (exist) {
      return toast.error("CUIDADO! Esse parâmetro já foi adicionado!", {
        position: toast.POSITION.TOP_RIGHT
      })
    }

    if (listParameterDescription.length > 0) {
      let new_parameters_description = listParameterDescription.filter((item) => item.name === values.parameter_description)

      if (new_parameters_description.length === 0) {
        await createParameterDescription({ "name": values.parameter_description })
      }
    } else {
      await createParameterDescription({ "name": values.parameter_description })
    }

    // @ts-ignore
    setParameters([...parameters, {
      name: values.parameter_name.replaceAll(' ', '-'),
      description: values.parameter_description,
      local: localParameter,
      fixed: fixedParameter === "1",
      value_type: valueTypeParameter,
      value: values.parameter_value,
      required: required === "1",
      boolean_default: values.boolean_default,
      boolean_value: values.boolean_value,
    }])
    setFieldValue("parameter_name", "")
    setFieldValue("parameter_description", "")
    // setLocalParameter("1")
    // setRequired("1")
    setValueTypeParameter("1")
    setFixedParameter("0")
    setFieldValue("parameter_value", "")
    setFieldValue("boolean_default", "")
    setFieldValue("boolean_value", "")
  }

  async function createParameterDescription(data: LooseObject) {
    await api.post('api/create_description_api/', data)
      .then(async (response: any) => await getDescriptionParameters())
  }

  const removeParameter = (parameter_name: string) => {
    const newParameters = parameters.filter((parameter) => {
      return parameter.name !== parameter_name
    })
    setParameters(newParameters)
  }

  const dysplayRequired = (value: boolean | undefined) => {
    return value ? "Obrigatório" : "Não obrigatório"
  }

  const displayFixed = (value: boolean | undefined) => {
    return value ? "Valor fixo" : "Valor não fixo"
  }

  async function createOrUpdateApi() {
    const data = {
      category: category,
      name: values.name,
      url: values.url,
      description: values.description,
      request_type: requestType,
      method: method,
      response_type: responseType,
      dynamic_token: dynamicToken,
      dynamic_token_id: values.dynamic_token_id,
      parameters: parameters,
      structure: values.jsonstructure,
      webhook: values.webhook
    }

    if (id) {
      toast.loading("Atualizando...", {
        position: toast.POSITION.TOP_RIGHT,
      })

      var resUpdated = await api.put(`api/update/${id}/`, data) as { [key: string]: any }

      toast.dismiss()

      if (resUpdated.status === 200) {
        toast.success("Dados atualizados com sucesso!", {
          position: toast.POSITION.TOP_RIGHT
        })
      } else {
        toast.error("Ops... Tivemos um problema, por favor tente novamente mais tarde!", {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    } else {
      const newData = { ...data, provider: provider_id }

      toast.loading("Criando...", {
        position: toast.POSITION.TOP_RIGHT,
      })

      var resCreated = await api.post('api/create/', newData) as { [key: string]: any }

      toast.dismiss()

      if (resCreated.status === 201) {
        setFieldValue("name", "")
        setFieldValue("url", "")
        setFieldValue("description", "")
        setRequestType("1")
        setMethod("1")
        setResponseType("1")
        setDynamicToken(false)
        setCategory("1")
        setParameters([])
        toast.success("API cadastrada com sucesso!", {
          position: toast.POSITION.TOP_RIGHT
        })
      } else {
        toast.error("Ops.. Tivemos um problema, por favor tente novamente mais tarde!", {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    }
  }

  function ModalInfo({ open, item }: ModalInfoProps) {
    return (
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="modal-modal-title" variant="h5" component="h2" textAlign="center">
            DADOS DO PARAMETRO: <strong style={{ color: LightMode.info.main }}>{item?.name}</strong>
          </Typography>
          <ul>
            <li><strong>Descrição:</strong> {item?.description}</li>
            {/* @ts-ignore */}
            <li><strong>Onde o parâmetro será enviado:</strong> {dysplayLocal[item?.local]}</li>
            <li><strong>Parâmetro obrigatório:</strong> {dysplayRequired(item?.required)}</li>
            {/* @ts-ignore */}
            <li><strong>Tipo de valor:</strong> {dysplayValueType[item?.value_type]}</li>
            <li><strong>Valor fixado:</strong> {displayFixed(item?.fixed)}</li>
            {
              item?.fixed ? <li><strong>Valor:</strong> {item?.value}</li> : <></>
            }
            {
              item?.value_type === '3'
                ? <>
                  <li><strong>Valor padrão:</strong> {item?.boolean_default}</li>
                  <li><strong>Valor se marcado:</strong> {item?.boolean_value}</li>
                </>
                : <></>
            }
          </ul>

          <Grid
            container
            gap={3}
            marginTop={3}
            alignItems="center"
            justifyContent="center">
            <Button variant="outlined" size="large" color="info" onClick={() => setOpenInfo(-1)}>
              Fechar
            </Button>
          </Grid>
        </Box>
      </Modal>
    )
  }

  const getTokensApis = useCallback(async () => {
    if (urlTokenApis) {
      await api.get(urlTokenApis).then((response: any) => {
        setDynamicsTokens(response.content.results)
      })
    }
  }, [urlTokenApis])

  const handleChangeDynamicToken = () => {
    setDynamicToken(!dynamicToken)
    if (!dynamicToken) getTokensApis()
  }

  useEffect(() => {
    const getApi = async () => {
      getTokensApis()
      const response = await api.get(`api/retrieve/${id}/`) as RetrieveApiProps

      setFieldValue('name', response.content.name)
      setFieldValue('url', response.content.url)
      setFieldValue('description', response.content.description)
      setFieldValue('webhook', response.content.webhook)
      setRequestType(response.content.request_type)
      setMethod(response.content.method)
      setResponseType(response.content.response_type)
      setDynamicToken(response.content.dynamic_token)
      setFieldValue("dynamic_token_id", response.content.dynamic_token_id)
      setCategory(response.content.category)
      setParameters(response.content.parameters)
      setUrlTokenApis(`api/list/${response.content.provider}/?category=2`)
      setFieldValue("jsonstructure", response.content.structure)
    }

    if (id) {
      setTitleBar(`API - ${values.name} (${location.state.fantasy_name})`)
      getApi().catch(console.error)
    } else {
      setTitleBar(`Nova API para o fornecedor ${location.state.fantasy_name}`)
      setUrlTokenApis(`api/list/${provider_id}/?category=2`)
    }

    getDescriptionParameters()
  }, [id, setFieldValue, provider_id, getTokensApis])

  useLayoutEffect(() => {
    setPathTitleBar(undefined)
  }, [setPathTitleBar])

  return (
    <>
      <ToastContainer />
      <ModalInfo open={openInfo !== -1} item={parameters[openInfo]} />
      <form onSubmit={handleSubmit}>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Grid item md={12} xs={12}>
            INFORMAÇÕES GERAIS
          </Grid>
          <Grid item md={4} xs={12}>
            <FormControl fullWidth>
              <InputLabel id="category-label">Categoria</InputLabel>
              <Select
                fullWidth
                required={true}
                labelId="category-label"
                id="category"
                name="category"
                value={category}
                label="Categoria"
                onChange={handleCategoryChange}
              >
                {Categories?.map((item) => (
                  <MenuItem key={`category-${item.id}`} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              fullWidth
              required={true}
              id="name"
              name="name"
              label="Nome"
              value={values.name}
              onChange={handleChange}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              fullWidth
              required={true}
              id="url"
              name="url"
              label="URL"
              value={values.url}
              onChange={handleChange}
              error={touched.url && Boolean(errors.url)}
              helperText={touched.url && errors.url}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              fullWidth
              multiline
              rows={4}
              id="description"
              name="description"
              label="Descrição"
              value={values.description}
              onChange={handleChange}
              error={touched.description && Boolean(errors.description)}
              helperText={touched.description && errors.description}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormControl fullWidth>
              <InputLabel id="request_type-label">Tipo de requisição</InputLabel>
              <Select
                fullWidth
                required={true}
                labelId="request_type-label"
                id="request_type"
                name="request_type"
                value={requestType}
                label="Tipo de requisição"
                onChange={handleResquestTypeChange}
              >
                {RequestTypes?.map((item) => (
                  <MenuItem key={`request-type-${item.id}`} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12}>
            <FormControl fullWidth>
              <InputLabel id="method-label">Método</InputLabel>
              <Select
                fullWidth
                required={true}
                labelId="method-label"
                id="method"
                name="method"
                value={method}
                label="Método"
                onChange={handleMethodChange}
              >
                {Methods?.map((item) => (
                  <MenuItem key={`method-${item.id}`} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12}>
            <FormControl fullWidth>
              <InputLabel id="response_type-label">Tipo de resposta</InputLabel>
              <Select
                fullWidth
                required={true}
                labelId="response_type-label"
                id="response_type"
                name="response_type"
                value={responseType}
                label="Tipo de resposta"
                onChange={handleResponseTypeChange}
              >
                {ResponseTypes?.map((item) => (
                  <MenuItem key={`response-type-${item.id}`} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* Token dinamico */}
          {
            category === "1"
              ? <Grid item md={12} xs={12}>
                <Alert color="info" severity="info" variant="outlined">
                  <FormGroup>
                    <FormControlLabel control={<Switch color="info" checked={dynamicToken} onChange={handleChangeDynamicToken} />} label="Ative essa opção caso haja a necessidade de utilizar tokens dinâmicos nessa integração" />
                  </FormGroup>
                  <Typography variant="body2" paragraph>
                    Dessa forma, você primeiro deve cadastrar a integração para se obter tokens de forma dinâmica e então ativa essa opção e vincula com a integração.
                  </Typography>
                  {
                    dynamicToken
                      ? <FormControl fullWidth>
                        <InputLabel id="dynamic_token_id-label">Selecione uma API de token dinâmico</InputLabel>
                        <Select
                          fullWidth
                          required={true}
                          labelId="dynamic_token_id-label"
                          id="dynamic_token_id"
                          name="dynamic_token_id"
                          value={values.dynamic_token_id}
                          label="Selecione uma API de token dinâmico"
                          onChange={handleChange}
                        >
                          {dynamicsTokens?.map((item) => (
                            <MenuItem key={`dynamic_token_id-${item.id}`} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      : <></>
                  }
                </Alert>
              </Grid>
              : <></>
          }
          <Grid item md={12} xs={12}>
            <Alert color="info" severity="info" variant="outlined" style={{ alignItems: 'center' }}>
              <FormGroup>
                <FormControlLabel control={<Switch color="info" checked={values.webhook} onChange={() => setFieldValue('webhook', !values.webhook)} />} label="Ative essa opção caso a API funcione com webhook" />
              </FormGroup>
            </Alert>
          </Grid>
          {/* Variaveis */}
          <Grid item md={12} xs={12}>
            PARÂMETROS
          </Grid>
          <Grid item md={12} xs={12}>
            <Alert severity="info">
              Parâmetros necessários para funcionamento da API
            </Alert>
          </Grid>
          <Grid
            item
            md={12} xs={12}
            alignItems="center"
            alignContent="center"
          >
            <List
              dense
              disablePadding
              sx={{ width: '100%' }}
            >
              {parameters?.map((item, index) => (
                <ListItem
                  key={item.name}
                  disablePadding
                >
                  <ListItemAvatar>
                    <Avatar>
                      <AutoAwesomeIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemButton
                    onClick={() => { setOpenInfo(index) }}
                  >
                    <ListItemText
                      primary={item.description}
                      secondary={item.name}
                    />
                  </ListItemButton>
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={() => { removeParameter(item.name) }}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              fullWidth
              id="parameter_name"
              name="parameter_name"
              label="Parâmetro"
              value={values.parameter_name}
              onChange={handleChange}
              helperText="Exatamente conforme documentação do fornecedor"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Autocomplete
              id="parameter_description"
              freeSolo
              options={listParameterDescription.length > 0 ? listParameterDescription.map((item) => item.name) : []}
              onChange={(e: any) => { setFieldValue('parameter_description', e.target.textContent) }}
              renderInput={
                (params) =>
                  <TextField
                    {...params}
                    fullWidth
                    name="parameter_description"
                    label="Descrição"
                    value={values.parameter_description}
                    onChange={handleChange}
                    helperText="Texto que será exibido para o seu cliente"
                  />
              }
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormControl fullWidth>
              <InputLabel id="local_parameter-label">
                Onde o parâmetro será enviado
              </InputLabel>
              <Select
                fullWidth
                labelId="local_parameter-label"
                id="local_parameter"
                name="local_parameter"
                value={localParameter}
                label="Onde o parâmetro será enviado"
                onChange={handleLocalParameterChange}
              >
                {LocalParameter?.map((item) => (
                  <MenuItem key={`local-parameter-${item.id}`} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                Conforme documentação do fornecedor
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <InputLabel id="required-label">Parâmetro obrigatório?</InputLabel>
              <Select
                fullWidth
                labelId="required-label"
                disabled={valueTypeParameter === "3"}
                id="required"
                name="required"
                value={required}
                label="Parâmetro obrigatório?"
                onChange={handleRequiredChange}
              >
                <MenuItem value="1">Sim</MenuItem>
                <MenuItem value="0">Não</MenuItem>
              </Select>
              <FormHelperText>
                Se o campo não for preenchido pelo seu cliente, <strong>NÃO</strong> será possível realizar a chamada
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <InputLabel id="value_type_parameter-label">Tipo de valor</InputLabel>
              <Select
                fullWidth
                labelId="value_type_parameter-label"
                id="value_type_parameter"
                name="value_type_parameter"
                value={valueTypeParameter}
                label="Tipo de valor"
                onChange={handleValueTypeParameterChange}
              >
                {ValuesTypes?.map((item) => (
                  <MenuItem key={`value-type-${item.id}`} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormHelperText>
              Tipo de informação que seu cliente deve informar (Seguir documentação do fornecedor)
            </FormHelperText>
          </Grid>
          {
            ['1', '2'].indexOf(valueTypeParameter) > -1
              ? <>
                <Grid item md={fixedParameter === '1' ? 6 : 12} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="fixed_parameter-label">Valor fixado?</InputLabel>
                    <Select
                      fullWidth
                      labelId="fixed_parameter-label"
                      id="fixed_parameter"
                      name="fixed_parameter"
                      value={fixedParameter}
                      label="Valor fixado?"
                      onChange={handleFixedParameterChange}
                    >
                      <MenuItem value="0">Não</MenuItem>
                      <MenuItem value="1">Sim</MenuItem>
                    </Select>
                    <FormHelperText>
                      Se o valor for fixado, seu cliente <strong>NÃO</strong> precisará preencher esse campo
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {
                  fixedParameter === '1'
                    ? <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        helperText="Você precisa dicionar um valor para parâmetros fixos"
                        type={valueTypeParameter === '2' ? 'number' : 'text'}
                        id="parameter_value"
                        name="parameter_value"
                        label="Informe o valor fixado"
                        value={values.parameter_value}
                        onChange={handleChange}
                      />
                    </Grid>
                    : <></>
                }
              </>
              : <></>
          }
          {
            valueTypeParameter === '3'
              ? <>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    id="boolean_default"
                    name="boolean_default"
                    label="Valor padrão"
                    value={values.boolean_default}
                    onChange={handleChange}
                    helperText="Um campo do tipo checkbox é exibido ao cliente e caso ele NÃO marque a opção, esse será o valor enviado ao fornecedor"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    id="boolean_value"
                    name="boolean_value"
                    label="Valor se marcado"
                    value={values.boolean_value}
                    onChange={handleChange}
                    helperText="Um campo do tipo checkbox é exibido ao cliente e caso ele marque a opção, esse será o valor enviado ao fornecedor"
                  />
                </Grid>
              </>
              : <></>
          }
        </Grid>
        <Grid
          container
          spacing={2}
          direction="column"
          alignItems="center"
          justifyContent="space-evenly"
        >
          <Grid
            item
            md={12} xs={12}
            alignItems="center"
            alignContent="center"
            marginTop={5}
            marginBottom={5}
          >
            <Button
              variant="contained"
              size="medium"
              color="primary"
              onClick={addParameter}
            >
              Adicionar
            </Button>
          </Grid>

          <Grid
            item md={12} xs={12}
            style={{ width: '100%' }}
          >
            <Grid item md={12} xs={12}>
              ESTRUTURA JSON
            </Grid>

            <Grid item md={12} xs={12}>
              <br />       
              <TextField
                fullWidth
                multiline
                rows={4}
                id="jsonstructure"
                name="jsonstructure"
                label="JSON"
                value={values.jsonstructure}
                onChange={handleChange}
                error={touched.description && Boolean(errors.jsonstructure)}
                helperText={touched.jsonstructure && errors.jsonstructure}
              />
            </Grid>
          </Grid>
          <Grid
            item
            md={12} xs={12}
            alignItems="center"
            alignContent="center"
            marginTop={5}
            marginBottom={5}
          >
            <Button
              variant="contained"
              size="large"
              color="success"
              type="submit"
              disabled={
                parameters.length === 0 || values.name.length === 0 || values.url.length === 0
              }
            >
              {id ? 'Atualizar' : 'Cadastrar'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  )
}