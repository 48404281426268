import React, { useState, useLayoutEffect, useEffect } from "react";
import { useItemsStore } from "../../../contexts/zustand/onboarding"
import { useAuth } from "../../../hooks/useAuth"
import { useParams, useNavigate } from "react-router-dom"
import * as api from '../../../services/api'
import { useCore } from "../../../hooks/useCore";
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { DraggableItem } from "../../../types/onboarding";
import { choicesFields, optionsFields, optionsAttachments, optionSection } from "../ChoicesFields/choices";
import SendOnboarding from "../SendOnboarding";
import EditFieldOptions from "../Editfield";
import Checkbox from '@mui/material/Checkbox';
import FactCheckIcon from '@mui/icons-material/FactCheck'

import {
    Menu, MenuItem, Paper, Box, InputAdornment, TextField,
    Typography, Button, Accordion, AccordionSummary, AccordionDetails,
    FormControlLabel, IconButton,
} from '@mui/material';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import { Chip, Grid, Tooltip } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ImportExportIcon from '@mui/icons-material/ImportExport';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import AddIcon from "@mui/icons-material/Add";

import InfoIcon from "@mui/icons-material/Info";

import ProtectRoutesOnboarding from "../index";

import { toast, ToastContainer } from "react-toastify";

import { LoadingPage } from "../../../components/LoadingPage"

const PRODUCT_ID_OCR_DOCUMENTOS = process.env.REACT_APP_PRODUCT_ID_OCR_DOCUMENTOS
const PRODUCT_ID_CNH_SERPRO = process.env.REACT_APP_PRODUCT_ID_CNH_SERPRO

type SelectChangeEvent3 = {
    target: {
        name: string;
        checked: boolean;
    };
}

type ItemContent = {
    content: {
        id: string;
        created_at: string;
        name_model: string;
        title_model: string;
        model_onboarding: any[];
        validations_input: any;
    }
};

const filter_apis_validations: { [key: string]: string } = {
    'cnh': 'validation_cnh',
    'selfie': 'validation_selfie',
    'input': 'validation_input'
}

export default function OnboardingCreate() {

    const { id_onboarding } = useParams();

    const { user } = useAuth()

    const { items, totalPrice, validations, validationsOptions, addItems, updateItems, removeItems, updateValidation, toggleValidation, toggleRemoveValidation, clearFormValues } = useItemsStore()

    const navigate = useNavigate()

    const { setTitleBar, setPathTitleBar } = useCore();

    const [isClicked, setIsClicked] = useState<string[]>([]);
    const [hoveredId, setHoveredId] = useState<string | null>(null);

    const [openSendOnboarding, setOpenSendOnboarding] = useState<boolean>(false);
    const [openEditFiled, setOpenEditFiled] = useState<boolean>(false);
    const [getValueKey, setGetValueKey] = useState<string>("");
    const [typeEditField, setTypeEditField] = useState<number>(0);

    const [idOnboarding, setIdOnboarding] = useState<string>('')

    const [checkedAllRequiredQuestions, setCheckedAllRequiredQuestions] = useState<boolean>(false);

    const [nameForm, setNameForm] = useState<string>("");
    const [titleForm, setTitleForm] = useState<string>("");

    const [expanded, setExpanded] = useState<string | false>(false);

    const [loading, setLoading] = useState<boolean>(false)

    useLayoutEffect(() => {
        setTitleBar("Onboarding");
        setPathTitleBar("");
    }, [setPathTitleBar, setTitleBar]);

    useEffect(() => {
        clearFormValues()
        setLoading(true)
        const fetchProducts = async () => {
            try {
                const response = await api.get(`company/retrieve/${user?.corporate_id!}/`) as any;

                const convertToFloat = (value: string | number) => {
                    if (typeof value === "string") {
                        return parseFloat(value.replace(",", "."));
                    }
                    return value;
                };

                const result = Object.keys(filter_apis_validations).reduce((acc, key) => {
                    const additionalPriceItem = response.content.prices_list[0].price_products.find(
                        (item: any) => item.product__id === PRODUCT_ID_OCR_DOCUMENTOS
                    );

                    acc[key] = response.content.prices_list[0].price_products
                        .filter((item: any) => item.validation_onboarding === filter_apis_validations[key])
                        .map((item: any) => {

                            if (item.product__id === PRODUCT_ID_CNH_SERPRO && additionalPriceItem) {
                                const additionalPrice = additionalPriceItem.price;
                                const currentPrice = item.price;
                                item.price = String((convertToFloat(currentPrice) + convertToFloat(additionalPrice))).replace('.', ',')
                            }

                            return {
                                id: item.product__id,
                                name: item.product__name,
                                price: item.price,
                                description: item.product__description
                            };
                        });

                    return acc;
                }, {} as Record<string, Array<{ id: number; name: string; price: string; description: string; }>>);

                toggleValidation(result)

                if (!id_onboarding) {
                    setLoading(false)
                    return
                }

                const responseOnboarding = await api.get(`/onboarding/list/${id_onboarding}/`) as { [key: string]: any };
                const modelOnboarding = responseOnboarding as ItemContent;
                const validationsInputOnboarding = modelOnboarding.content.validations_input;

                setNameForm(modelOnboarding.content.name_model);
                setTitleForm(modelOnboarding.content.title_model);
                updateItems(modelOnboarding.content.model_onboarding, true);

                if (validationsInputOnboarding) updateValidation('input', '', validationsInputOnboarding)
                
                setTimeout(() => {
                    setLoading(false)
                }, 200)

            } catch (error: any) {
                toast.error(error?.response?.data?.detail || 'Erro ao trazer resultados!!', {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setLoading(false)
            }
        };
        fetchProducts();
    }, [user?.corporate_id, clearFormValues, id_onboarding, toggleValidation, updateItems, updateValidation]);


    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const validateOnboardingForm = (): boolean => {
        if (nameForm === 'Nome do Onboarding' || nameForm === '') {
            toast.dismiss();
            toast.error('Digite o nome do onboarding!!!');
            return false;
        }

        if (titleForm === 'Título do Onboarding' || titleForm === '') {
            toast.dismiss();
            toast.error('Digite o título do onboarding!!!');
            return false;
        }

        const typesToCheck = ['13', '15', '16'];

        const updatedItems = items.map((item) => {
            let error = '';

            if (typesToCheck.includes(item.type as string) && (!item.options || item.options.length < 2)) {
                error = 'Adicione pelo menos 2 opções nos campos multi escolha';
            } else if (!item.label) {
                error = 'Adicione um nome';
            }

            return { ...item, error };
        });

        updateItems(updatedItems);

        const hasError = updatedItems.some(item => item.error && item.error !== '');

        if (hasError) toast.error('Existem campos não preenchidos', { autoClose: 2000 })

        return !hasError;

    };

    const handleSendOnboadingOpen = () => {
        const checkNameTitleForm = validateOnboardingForm()

        if (!checkNameTitleForm) return

        setHoveredId(null);

        if (items.length > 0) {
            setOpenSendOnboarding(true);
        } else {
            toast.dismiss();
            toast.error('Adicione pelo menos 1 campo para enviar o formulário');
        }
    };

    const handleEditFieldOpen = (uuidKey: string, typeEditField: number) => {
        setOpenEditFiled(true);
        setGetValueKey(uuidKey);
        setTypeEditField(Number(typeEditField));

    };

    const handleNameForm = (event: React.ChangeEvent<HTMLInputElement>) => {
        const valueText = event.target.value;

        if (valueText.length <= 90) {
            setNameForm(valueText);
        } else if (valueText === "") {
            setNameForm("Nome do Onboarding");
        }
    };

    const handleTitleForm = (event: React.ChangeEvent<HTMLInputElement>) => {
        const valueText = event.target.value;

        if (valueText.length <= 90) {
            setTitleForm(valueText);
        } else if (valueText === "") {
            setTitleForm("Título do Onboarding");
        }
    };

    const handleDragEnd = (item: DraggableItem) => {
        addItems({
            ...item,
            required: checkedAllRequiredQuestions ? false : true
        })

    };

    const reorder = (items: DraggableItem[], startIndex: number, endIndex: number) => {
        if (startIndex === endIndex) return items;

        const newItems = [...items];
        const [movedItem] = newItems.splice(startIndex, 1);

        if (!movedItem) return items;

        newItems.splice(endIndex, 0, movedItem);

        return newItems
    };

    const handleItemDropped = (result: any) => {

        if (!result.destination) return;

        const reorderedItems = reorder(items, result.source.index, result.destination.index);
        updateItems([...reorderedItems], false);

    };

    const DraggableTooltip = ({ item, index }: any) => {
        const addField = (type: string, id: string) => {

            const existingItemCamera = items.find((selectedItem) => selectedItem.type === '12');
            const existingItemCheckbox = items.find((selectedItem) => selectedItem.type === '15');

            if (!existingItemCamera && type === '12') {
                const userResponse = window.confirm('ATENÇÃO: Esse campo restringe a resposta do onboarding para que seja acessado somente pelo celular. Deseja continuar?');

                if (!userResponse) {
                    return setIsClicked((prev) => prev.filter((clickedId) => clickedId !== id));
                }
            }

            if (!existingItemCheckbox && type === '15') {
                const userResponse = window.confirm('ATENÇÃO: Esse campo permite selecionar 2 opções!!');

                if (!userResponse) {
                    return setIsClicked((prev) => prev.filter((clickedId) => clickedId !== id));
                }
            }

            if (id === '2') {
                const order = ['7', '3', '4', '5', '6'];
                const additionalItems = optionsFields
                    .filter(field => order.includes(field.id))
                    .sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id));

                additionalItems.forEach(field => {
                    addItems({
                        ...field,
                        required: checkedAllRequiredQuestions ? false : true
                    });
                });
            } else {
                addItems({
                    ...item,
                    required: checkedAllRequiredQuestions ? false : true
                })
            }

            setIsClicked((prev) => [...prev, id]);

            setTimeout(() => {
                setIsClicked((prev) => prev.filter((clickedId) => clickedId !== id));
            }, 400);
        };

        return (
            <>
                <Button
                    onMouseEnter={() =>
                        setHoveredId(item.type === '20' || item.type === '21' ? item.id : null)
                    }
                    onClick={(e) => {
                        e.stopPropagation();
                        setIsClicked((prev) =>
                            prev.includes(item.id)
                                ? prev.filter((id) => id !== item.id)
                                : [...prev, item.id]
                        );
                        addField(item.type, item.id);
                    }}
                    endIcon={<AddIcon />}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        backgroundColor: isClicked.includes(item.id) ? '#32CD32' : '#FFA890',
                        borderRadius: '16px',
                        padding: '8px 12px',
                        color: '#FFFFFF',
                        cursor: 'pointer',
                        border: isClicked.includes(item.id)
                            ? "2px solid #32CD32"
                            : "none",
                        '&:hover': {
                            backgroundColor: '#FFA890 !important',
                            opacity: 0.9,
                        },
                        "& .MuiButton-startIcon": {
                            border: '2px solid #FFFFFF',
                            borderRadius: '50%',
                            width: '22px',
                            height: '22px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: isClicked.includes(item.id) ? '#32CD32' : '#FFA890',
                        },
                        "&:hover .MuiButton-startIcon": {
                            backgroundColor: '#FFA890',
                            color: '#E0E0E0',
                        },
                    }}
                >
                    {item.label}
                </Button>

                {hoveredId === item.id && (
                    <Tooltip
                        open={hoveredId === item.id}
                        onOpen={() => setHoveredId(item.id)}
                        onClose={() => setHoveredId(null)}
                        slotProps={{
                            tooltip: {
                                sx: {
                                    color: "#514E6A",
                                    backgroundColor: "transparent",
                                },
                            },
                        }}
                        title={
                            <Box
                                onMouseEnter={() => setHoveredId(item.id)}
                                onMouseLeave={() => setHoveredId(item.id)}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    backgroundColor: '#FFF',
                                    border: '1px solid #CCC',
                                    borderRadius: '8px',
                                    padding: '8px',
                                }}
                            >
                                <Button
                                    onClick={() => {
                                        addItems({
                                            ...item,
                                            required: checkedAllRequiredQuestions ? false : true,
                                            validations: false,
                                        });
                                    }}
                                    sx={{
                                        color: 'black',
                                        fontSize: '12px',
                                        '&:hover': {
                                            backgroundColor: '#EEEEEE',
                                            color: 'black',
                                        },
                                    }}
                                >
                                    Sem validação
                                </Button>
                                <Button
                                    onClick={() => {
                                        addItems({
                                            ...item,
                                            required: checkedAllRequiredQuestions ? false : true,
                                            validations: true,
                                        });
                                    }}
                                    sx={{
                                        color: 'black',
                                        fontSize: '12px',
                                        '&:hover': {
                                            backgroundColor: '#EEEEEE',
                                            color: 'black',
                                        },
                                    }}
                                >
                                    Com validação
                                </Button>
                            </Box>
                        }
                        arrow
                        placement="bottom"
                    >
                        <div />
                    </Tooltip>

                )}
            </>
        );
    };

    const DraggableItem = ({ item, index }: any) => {

        const MoreOptionsMenu = ({ item }: any) => {
            const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

            const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
                setAnchorEl(event.currentTarget);
            };

            const handleClose = () => {
                setAnchorEl(null);
            };

            const editField = () => {
                handleEditFieldOpen(item.key, item.id_choice);
                handleClose();
            };

            const duplicatedField = () => {
                addItems(item)
            };

            const deleteField = () => {
                removeItems(item.key, item.type, item.validations)
                handleClose();
            };

            const menuItems = () => (
                <Box>
                    <MenuItem onClick={() => editField()}>
                        <Typography sx={{ display: 'flex', alignItems: 'center', gap: 0.7, cursor: 'pointer' }}>
                            Editar
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={() => duplicatedField()}>
                        <Typography sx={{ display: 'flex', alignItems: 'center', gap: 0.7, cursor: 'pointer' }}>
                            Duplicar
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={() => deleteField()}>
                        <Typography sx={{ display: 'flex', alignItems: 'center', gap: 0.7, cursor: 'pointer', color: '#FFA890' }}>
                            Deletar
                        </Typography>
                    </MenuItem>
                </Box>
            );

            return (
                <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 0.7 }}>
                    <IconButton onClick={handleClick}>
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        disableScrollLock={true}
                    >
                        {menuItems()}
                    </Menu>

                </Grid>
            );
        };

        return (
            <Draggable draggableId={`${item.key}-${index}`} index={index}>
                {(provided) => (
                    <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            margin: "20px 0 0 15px",
                            ...provided.draggableProps.style,
                        }}
                    >
                        <Box sx={{ display: "flex", alignItems: 'center', marginRight: '8px' }}>
                            <ImportExportIcon fontSize={'medium'} sx={{ color: '#FFA089', height: '45px', width: '24px' }} />
                        </Box>

                        <Box
                            {...provided.dragHandleProps}
                            sx={{ width: '100%', display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                            <div style={{ width: '100%' }}>
                                {choicesFields[item.id_choice].value(item)}
                            </div>
                        </Box>

                        <Box sx={{ display: "flex", alignItems: "center", marginLeft: '8px' }}>
                            <MoreOptionsMenu item={item} />
                        </Box>
                    </div>
                )}
            </Draggable>
        );
    };

    const allRequiredQuestions = async (e: SelectChangeEvent3) => {
        const checked = e.target.checked
        setCheckedAllRequiredQuestions(checked)

        if (checked) {
            const updateItem = items.map(item => ({
                ...item,
                required: false
            }));

            updateItems(updateItem)
        } else {
            const updateItem = items.map(item => ({
                ...item,
                required: true
            }));

            updateItems(updateItem)
        }

    };

    const AddSession = () => {
        addItems(optionSection)
    }

    const SavedOnboarding = async () => {

        const checkNameTitleForm = validateOnboardingForm()

        if (!checkNameTitleForm) return

        if (items.length === 0) {
            toast.dismiss()
            return toast.error('Adicione pelo menos 1 campo para salvar o formulário');
        }

        let position = 0;
        const itemsQuestions = items.map(item => {
            return {
                ...item,
                position: position++,
                validations: validations && validations[item.key!] ? validations[item.key!] : []
            };
        });

        const data = {
            'created_by': user?.id,
            'company': user?.corporate_id,
            'name_model': nameForm,
            'title_model': titleForm,
            'model_onboarding': itemsQuestions,
            'validations_input': validations['input']
        }

        if (id_onboarding) {
            const response = await api.post(`/onboarding/create/?id_onboarding=${id_onboarding}`, data) as { [key: string]: any };

            if (response.status === 201) {
                toast.success('Onboarding Salvo com Sucesso!!!');
            } else if (response.status === 400) {
                toast.error(`${response.data.content.message}`, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                toast.error("Ops.. Tivemos um problema, por favor tente novamente mais tarde!", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }

            return
        }

        const response = await api.post(`/onboarding/create/?id_onboarding=${idOnboarding}`, data) as { [key: string]: any };


        if (response.status === 201) {
            if (!idOnboarding) setIdOnboarding(response.data.content.id)

            toast.success('Onboarding Salvo com Sucesso!!!');
        } else if (response.status === 400) {
            toast.error(`${response.data.content.message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            toast.error("Ops.. Tivemos um problema, por favor tente novamente mais tarde!", {
                position: toast.POSITION.TOP_RIGHT
            });
        }

    }


    return (
        <ProtectRoutesOnboarding>
            <ToastContainer />

            {loading && <LoadingPage />}

            {openSendOnboarding && (
                <SendOnboarding
                    open={openSendOnboarding}
                    setOpen={setOpenSendOnboarding}
                    nameModelOnboarding={nameForm}
                    titleOnboarding={titleForm}
                />
            )}

            {<EditFieldOptions open={openEditFiled} setOpen={setOpenEditFiled} refKey={getValueKey} type={typeEditField} />}


            <DragDropContext onDragEnd={handleItemDropped}>
                <Grid container spacing={2} sx={{ width: '100%' }}>

                    <Grid item xs={12}>
                        <Paper elevation={3} sx={{ padding: '20px', display: 'flex', flexDirection: 'column', gap: 4, backgroundColor: '#FFFFFF' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                <Button
                                    onClick={() => navigate('/onboarding')}
                                    sx={{
                                        padding: 0,
                                        minWidth: 'auto',
                                        height: '56px',
                                        width: '35px',
                                        marginRight: 2,
                                        '&:hover': {
                                            backgroundColor: 'transparent'
                                        }
                                    }}
                                >
                                    <KeyboardArrowLeftIcon
                                        sx={{
                                            backgroundColor: '#FFFFFF',
                                            border: '1px solid #D6D6D6',
                                            borderRadius: '12px',
                                            padding: '7px',
                                            height: '56px',
                                            width: '35px',
                                            color: 'black'
                                        }}
                                    />
                                </Button>

                                <TextField
                                    fullWidth
                                    label="Nome do onboarding"
                                    value={nameForm}
                                    variant="outlined"
                                    margin="none"
                                    onChange={handleNameForm}
                                    inputProps={{ maxLength: 90 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Limite máximo de 90 caracteres" placement="right">
                                                    <InfoIcon sx={{ color: 'gray', fontSize: 18 }} />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    label="Título do onboarding"
                                    value={titleForm}
                                    variant="outlined"
                                    margin="none"
                                    onChange={handleTitleForm}
                                    inputProps={{ maxLength: 90 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Limite máximo de 90 caracteres" placement="right">
                                                    <InfoIcon sx={{ color: 'gray', fontSize: 18 }} />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>



                            <Accordion
                                sx={{ backgroundColor: '#FFFFFF' }}
                                expanded={expanded === 'panel1'}
                                onChange={handleChange('panel1')}
                                TransitionProps={{ unmountOnExit: true }}
                            >
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>Anexos</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Grid container spacing={2}>
                                    {optionsAttachments.map((fields: any) => (
                                        <Grid item key={fields.id}>
                                            <DraggableTooltip
                                                item={fields}
                                                onDragEnd={() => handleDragEnd(fields)}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                                </AccordionDetails>
                            </Accordion>



                            <Accordion
                                sx={{ backgroundColor: '#FFFFFF' }}
                                expanded={expanded === 'panel2'}
                                onChange={handleChange('panel2')}
                                TransitionProps={{ unmountOnExit: true }}
                            >
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>Perguntas</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        {optionsFields.map((fields: any) => (
                                            <Grid item key={fields.id}>
                                                <DraggableTooltip
                                                    item={fields}
                                                    onDragEnd={() => handleDragEnd(fields)}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>


                            <Accordion sx={{ backgroundColor: '#FFFFFF', width: '100%' }} expanded={expanded === 'panel3'} onChange={handleChange('panel3')} TransitionProps={{ unmountOnExit: true }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>Consultas agregadas ao Onboarding</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2} gap={1} sx={{ marginLeft: '1px' }}>
                                        {validationsOptions['input'] && validationsOptions['input'].map((product: any) => (
                                            <Chip
                                                key={product.id}
                                                icon={<FactCheckIcon />}
                                                sx={{
                                                    '& .MuiChip-icon': {
                                                        color: validations['input'] && validations['input'].some((v: any) => v === product.id) ? "#FFFFFF" : "#616161",
                                                    }
                                                }}
                                                label={product.name}
                                                onClick={() => {
                                                    const isCurrentlyActive = validations['input'] && validations['input'].some((v: any) => v === product.id);
                                                    toggleRemoveValidation('input', product.id, !isCurrentlyActive, 'input');
                                                }}
                                                style={{
                                                    backgroundColor: validations['input'] && validations['input'].some((v: any) => v === product.id) ? "#32CD32" : "#EEEEEE",
                                                }}
                                            />
                                        ))}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>

                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checkedAllRequiredQuestions}
                                            defaultChecked
                                            onChange={allRequiredQuestions}
                                        />
                                    }
                                    label='Marcar todas perguntas como NÃO obrigatórias?'
                                />

                                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography><strong>Valor Onboarding</strong></Typography>
                                    <Typography>
                                        {totalPrice.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                    </Typography>
                                </Box>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={AddSession}
                                    sx={{
                                        height: "45px",
                                        color: '#FFFFFF',
                                        backgroundColor: '#9B4593',
                                        borderRadius: '8px',
                                        textTransform: 'none',
                                        '&:hover': {
                                            backgroundColor: '#9B4593',
                                        },
                                    }}
                                >
                                    Adicionar seção
                                </Button>
                            </Box>

                        </Paper>
                    </Grid>

                    {/* TELA 2*/}
                    <Grid item xs={12}>
                        <Paper
                            elevation={3}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                height: '500px',
                                backgroundColor: '#FFFFFF',
                                overflowY: 'auto'
                            }}
                        >
                            <Grid container>
                                <Grid
                                    item
                                    sx={{
                                        padding: '20px',
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 5,
                                        width: "100%",
                                        height: "100%",
                                    }}
                                >


                                    <Droppable droppableId="items" type="list" direction="vertical">
                                        {(provided) => (
                                            <Box
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                                sx={{
                                                    display: "flex",
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    flexDirection: "column",
                                                    height: "100%",
                                                    marginBottom: '15px',
                                                    backgroundColor: '#FFFFFF'
                                                }}
                                            >
                                                {items.map((item, index) => (
                                                    <DraggableItem key={`${item.key}-${index}`} item={item} index={index} />
                                                ))}

                                                {provided.placeholder}
                                            </Box>
                                        )}
                                    </Droppable>

                                </Grid>
                            </Grid>

                            <Box sx={{
                                padding: '20px',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                width: '100%',
                                height: '100px',
                                gap: 2,
                                backgroundColor: '#F9FAFB',
                            }}>

                                <Button
                                    sx={{
                                        height: "45px",
                                        backgroundColor: '#FFFFFF',
                                        color: 'black',
                                        borderRadius: '8px',
                                        padding: '10px 32px',
                                        textTransform: 'none'
                                    }}
                                    size="large"
                                    variant="contained"
                                    onClick={SavedOnboarding}
                                >
                                    Salvar Onboarding
                                </Button>

                                <Button
                                    sx={{
                                        height: "45px",
                                        color: '#FFFFFF',
                                        borderRadius: '8px',
                                        padding: '10px 32px',
                                        textTransform: 'none'
                                    }}
                                    size="large"
                                    variant="contained"
                                    onClick={handleSendOnboadingOpen}
                                >
                                    Enviar Onboarding
                                </Button>

                            </Box>
                        </Paper>
                    </Grid>

                </Grid>
            </DragDropContext>
        </ProtectRoutesOnboarding>
    );
}