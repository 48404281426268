import List from "@mui/material/List"
import Input from "@mui/material/Input"
import Avatar from "@mui/material/Avatar"
import * as api from '../../../services/api'
import ListItem from "@mui/material/ListItem"
import { useCore } from "../../../hooks/useCore"
import IconButton from "@mui/material/IconButton"
import { LooseObject } from "../../../types/core"
import InputLabel from "@mui/material/InputLabel"
import DeleteIcon from '@mui/icons-material/Delete'
import SearchIcon from '@mui/icons-material/Search'
import FormControl from "@mui/material/FormControl"
import ListItemText from "@mui/material/ListItemText"
import { toast, ToastContainer } from 'react-toastify'
import ListItemButton from "@mui/material/ListItemButton"
import InputAdornment from "@mui/material/InputAdornment"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import { Paginator } from "../../../components/Paginator"
import ModalDelete from "../../../components/ModalDelete"
import { ListApiProps, ApiProps } from "../../../types/api"
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { useParams, useLocation, useNavigate } from "react-router-dom"
import { useEffect, useState, useCallback, useLayoutEffect } from "react"

export default function Users(): JSX.Element {
  const navigate = useNavigate()
  const location: LooseObject = useLocation()
  let { id } = useParams()
  const { setTitleBar, setPathTitleBar, setPathData } = useCore()

  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [apis, setApis] = useState<ApiProps[] | undefined>([])
  const [idDelete, setIdDelete] = useState<string | undefined>()
  const [apisSearch, setApisSearch] = useState<ApiProps[] | undefined>([])
  const [count, setCount] = useState<number>(0)
  const [pageNumber, setPageNumber] = useState<number>(1)

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value)
    getApis(`api/list/${id}/?page=${value}`)
  }

  const getApis = useCallback(async (url: string) => {
    const response = await api.get(url) as ListApiProps
    setCount(response.content.count)
    setApis(response.content.results)
    setApisSearch(response.content.results)
  }, [])

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		let value = e.target.value
    search(value)
    setSearchValue(value)
	}

  function search(value: string) {
    setApisSearch(
      apis?.filter((item) => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1)
    )
  }

  function handleModalOpen(id: string) {
    setIdDelete(id)
    setOpen(true)
  }

  const handleModalClose = () => setOpen(false);

  const handleDelete = async () => {
    setLoading(true)
    const response = await api.destroy(`api/destroy/${idDelete}/`) as {[key:string]: any}
    if (response.status === 204) {
      let indexApis = apis?.findIndex((item, i) => item.id === idDelete)
      apis?.splice(indexApis!, 1)
      search(searchValue)
      toast.success("API excluída com sucesso!", {
        position: toast.POSITION.TOP_RIGHT
      })
    } else {
      toast.error("Falha ao tentar excluir API!", {
			  position: toast.POSITION.TOP_RIGHT
		  })
    }
    handleModalClose()
    setLoading(false)
  }

  useEffect(() => {
    getApis(`api/list/${id}/`).catch(console.error)
	}, [getApis, id])

  useLayoutEffect(() => {
    setTitleBar(`APIs do fornecedor ${location.state.fantasy_name}`)
    setPathTitleBar(`/new-api/${id}`)
    setPathData({fantasy_name: location.state.fantasy_name})
  }, [id, location.state.fantasy_name, setPathData, setPathTitleBar, setTitleBar])

	return (
		<>
      <ToastContainer />
      <ModalDelete open={open} textBody="Tem certeza que deseja excluir essa API? Todos os produtos que contém ela serão afetados." handleModalClose={handleModalClose} handleDelete={handleDelete} loading={loading} />
			<List dense disablePadding sx={{ width: '100%' }}>
        <ListItem 
          key='search' 
          disablePadding
        >
          <FormControl fullWidth sx={{ m: 1 }} variant="standard">
            <InputLabel htmlFor="standard-adornment-amount">Pesquisar</InputLabel>
            <Input
              id="input-search"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              onChange={handleChangeSearch}
            />
          </FormControl>
        </ListItem>
        {apisSearch?.map((item) => (
          <ListItem 
            key={item.id} 
            disablePadding 
            secondaryAction={
              <IconButton edge="end" aria-label="delete" onClick={() => {handleModalOpen(item.id)}}>
                <DeleteIcon />
              </IconButton>
            }
          >
            <ListItemButton onClick={
              () => {
                navigate(
                  `/api/${item.id}`, 
                  // @ts-ignore
                  {state: {fantasy_name: location.state.fantasy_name}}
                )
              }
            }>
              <ListItemAvatar>
                <Avatar>
                  <AccountCircleIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={`${item.name}`} secondary={item.description} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Paginator count={count} pageNumber={pageNumber} onChange={handleChangePage} />
		</>
	)
}