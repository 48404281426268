import React, { useState, useEffect } from "react";
import axios from "axios";
import * as api from "../../../services/api";
import { APIOnboarding } from "../../../services/api";
import { useAuth } from "../../../hooks/useAuth";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Grid, Box } from "@mui/material";
import Paper from "@mui/material/Paper";

import ProtectRoutesOnboarding from "../index";

import {
    Chip,
    Divider
} from "@mui/material";

import {
    choicesFields,
    optionsFields,
    optionsAttachments,
    optionSection,
} from "../ChoicesFields/choices";

import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

import Typography from "@mui/material/Typography";

import { toast, ToastContainer } from "react-toastify";


type ResponseQuestions = {
    id: string;
    label: string;
    created_at: Date;
    type?: string;
    options: string[];
    value: string;
    required: boolean;
    id_choice: string;
    position: number;
};

type ResponseAttachments = {
    id: string;
    created_at: Date;
    label: string;
    data: ResponseQuestions;
    file: string;
    position: number;
    type_question?: string;
};

type ResponseValidations = {
    checkview_data: {
        api: string;
        responses: {
            data_json: string[]
        }
    }
};

type DataSignatory = {
    label: string;
    type: string;
    value: string;
};

type Locale = {
    start: {
        latitude: string;
        longitude: string;
    };
    finishi: {
        latitude: string;
        longitude: string;
    };
};

type ResultItem = {
    id: string;
    data: DataSignatory[];
    onboarding_id: string;
    locale: Locale;
    created_at: string;
    updated_at: string;
    questions: ResponseQuestions[];
    attachments: ResponseAttachments[];
    response_questions: ResponseQuestions[];
    response_attachments: ResponseAttachments[];
    response_validations: ResponseValidations[];
    combinedItems: any;
};

type ApiResponse = {
    content: {
        results: ResultItem[];
        data: DataSignatory[];
        questions: ResponseQuestions[];
        attachments: ResponseQuestions[];
    };
};

interface GenerateValidationsProps {
    data: Record<string, any>;
    isList?: boolean;
}

const formattedLabels: { [key: string]: string } = {
    nome: "Nome",
    cpf_cnpj: "CPF/CNPJ",
    whatsapp: "Número de telefone",
    email: "Email",
    numero_processo: "Número do processo",
};

const formatDateAndHours = (dateToConvert: string) => {

    const dateTime = dateToConvert.split(' às ');

    const date = dateTime[0];
    const time = dateTime[1];

    const [day, month, year] = date.split('/');
    const [hours, minutes, seconds] = time.split(':');

    let dateObject = new Date(parseInt(year), parseInt(month) - 1, parseInt(day), parseInt(hours), parseInt(minutes), parseInt(seconds));

    dateObject.setHours(dateObject.getHours() - 3);

    const formattedDate = dateObject.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });

    const formattedTime = dateObject.toLocaleTimeString('pt-BR', {
        hour: '2-digit',
        minute: '2-digit'
    });

    return [formattedDate, formattedTime];
};

export default function ViewResponseSignatory() {
    const { user } = useAuth();

    const { id_signatory } = useParams();

    const navigate = useNavigate();

    const [resultSignatory, setResultSignatory] = useState<ApiResponse | null>(
        null
    );

    const [locale, setLocale] = useState<Locale | null>(null);

    useEffect(() => {
        const companyId = user?.corporate_id!;

        const fetchData = async () => {
            const getToken = (await api.get(`company/retrieve/${companyId}/`)) as {
                [key: string]: any;
            };
            const tokenOnboarding = getToken.content.token_onboarding;

            try {
                const response = await APIOnboarding.get(
                    `onboarding/signatory/list/?id=${id_signatory}&token=${tokenOnboarding}`
                );

                const orderdData = {
                    ...response.data,
                    content: {
                        ...response.data.content,
                        results: response.data.content.results.map((item: ResultItem) => {
                            if (item.locale !== null) {
                                if (item.locale.start && item.locale.finishi) {
                                    setLocale({
                                        start: {
                                            latitude: item.locale.start.latitude,
                                            longitude: item.locale.start.longitude,
                                        },
                                        finishi: {
                                            latitude: item.locale.finishi.latitude,
                                            longitude: item.locale.finishi.longitude,
                                        },
                                    });
                                }
                            }

                            const orderedResponseQuestions =
                                item.response_questions &&
                                item.response_questions.map((question) => ({
                                    ...question,
                                    type_question: "question",
                                    position: question.position,
                                }));

                            const obter = item.response_attachments && item.response_attachments.length > 0
                                ? item.response_attachments
                                : item.attachments || [];

                            const orderedResponseAttachments = Object.values(
                                obter.reduce((acc, attachment) => {
                                    const id = attachment.data ? attachment.data.id : attachment.id;
                                    if (
                                        !acc[id] ||
                                        (!attachment.created_at || !acc[id].created_at) ||
                                        new Date(attachment.created_at) > new Date(acc[id].created_at)
                                    ) {
                                        acc[id] = {
                                            ...attachment,
                                            type_question: "attachment",
                                            position: attachment.data ? attachment.data.position : attachment.position,
                                        };
                                    }

                                    return acc;
                                }, {} as Record<string, ResponseAttachments>)
                            );

                            const combinedItems = [
                                ...(orderedResponseQuestions ?? []),
                                ...(orderedResponseAttachments ?? []),
                            ].sort((a, b) => a.position - b.position);

                            return {
                                ...item,
                                combinedItems,
                            };
                        }),
                    },
                };

                setResultSignatory(orderdData);
            } catch (error) {
                toast.error("Erro ao trazer as respostas!", { autoClose: 2000 });
            }
        };

        fetchData();
    }, [id_signatory, user?.corporate_id]);

    if (!resultSignatory) return null;

    const formatResultData = (resultData: DataSignatory[]) => {
        const desiredOrder = [
            "numero_processo",
            "nome",
            "cpf_cnpj",
            "whatsapp",
            "email",
        ];

        const dataMap: { [key: string]: string } = {};
        resultData.forEach((field) => {
            dataMap[field.label] = field.value;
        });
        return desiredOrder.map((key) => ({
            label: formattedLabels[key],
            value: dataMap[key],
        }));
    };

    const downloadFileFromS3 = async (fileUrl: string) => {
        try {
            const response = await axios.get(fileUrl, {
                responseType: "blob",
            });

            const urlWithoutQuery = fileUrl.split("?")[0];
            const fileName = urlWithoutQuery.substring(
                urlWithoutQuery.lastIndexOf("/") + 1
            );

            const mimeType = response.headers["content-type"];

            const url = window.URL.createObjectURL(
                new Blob([response.data], { type: mimeType })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();

            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            toast.error("Erro ao baixar o arquivo");
        }
    };

    const visualizationPdf = async () => {
        const toastId = toast.loading("Gerando PDF, aguarde...");

        try {
            const response = (await api.post(
                "onboarding/createPdf/",
                resultSignatory
            )) as { [key: string]: any };

            const blob = new Blob([response.data], { type: "text/html" });
            const url = window.URL.createObjectURL(blob);
            const newWindow = window.open(url, "_blank");
            if (newWindow) newWindow.focus();

            toast.dismiss(toastId);
        } catch (error) {
            toast.dismiss(toastId);
            toast.error("Erro ao gerar PDF. Tente novamente.", { autoClose: 2000 });
        }
    };

    const GenerateList = ({ data, isList = false }: GenerateValidationsProps) => {
        return (
            <>
                {Object.entries(data).map(([key, value]) => {
                    const formattedKey = key.replace(/_/g, " ");

                    if (typeof value === "object" && value !== null) {
                        return (
                            <React.Fragment key={key}>
                                {isList && !isNaN(Number(formattedKey)) && (
                                    <li className="li-report subtitle">
                                        <strong>Item {Number(formattedKey) + 1}</strong>
                                    </li>
                                )}
                                {!isList && isNaN(Number(formattedKey)) && (
                                    <li className="li-report title">
                                        <strong>{formattedKey}</strong>
                                    </li>
                                )}
                                <GenerateList data={value} isList={value.constructor().toString().length === 0} />
                            </React.Fragment>
                        );
                    }

                    const displayValue = value === null || value === "null" || value === undefined || value === "undefined"
                        ? "Nada consta"
                        : value;

                    const displayKey = !isNaN(Number(key)) ? `Item ${Number(key) + 1}` : key;

                    return (
                        <li className="li-report" key={key}>
                            <strong>{displayKey}:</strong> {displayValue}
                        </li>
                    );
                })}
            </>
        );
    };

    return (
        <ProtectRoutesOnboarding>
            <ToastContainer />

            <Grid container spacing={2} sx={{ padding: 2 }}>
                <Grid item xs={12} md={8}>
                    <Paper elevation={3} sx={{ padding: 2, backgroundColor: "#FFFFFF" }}>
                        <Typography variant="h6" gutterBottom textAlign="center">
                            Dados de entrada
                        </Typography>

                        {resultSignatory &&
                            (() => {
                                const [sendDateAt, sendHoursAt] = formatDateAndHours(resultSignatory.content.results[0]?.created_at)
                                const [updateDateAt, updateHoursAt] = formatDateAndHours(resultSignatory.content.results[0]?.updated_at)
                                return resultSignatory.content.results.map(
                                    (result: ResultItem, index: number) => (
                                        <>
                                            {formatResultData(result.data).map((field, idx) => (
                                                <Grid item xs={12} key={idx} sx={{ mb: 1 }}>
                                                    <Typography sx={{ fontWeight: "bold" }}>
                                                        {field.value && `${field.label}:`}
                                                    </Typography>
                                                    <Typography>{field.value}</Typography>
                                                </Grid>
                                            ))}

                                            {index === 0 && (
                                                <Grid item xs={12} sx={{ mt: 2 }}>
                                                    <>
                                                        <Typography sx={{ fontWeight: "bold" }}>
                                                            Data de envio:
                                                        </Typography>

                                                        <Typography>{sendDateAt} às {sendHoursAt}</Typography>

                                                        <br />

                                                        <Typography sx={{ fontWeight: "bold" }}>
                                                            Data de finalização:
                                                        </Typography>
                                                        <Typography>{updateDateAt} às {updateHoursAt}</Typography>
                                                    </>
                                                </Grid>
                                            )}

                                            {locale ? (
                                                <>
                                                    <br />
                                                    <Typography sx={{ fontWeight: "bold" }}>
                                                        Localização:
                                                    </Typography>
                                                    {locale.start.latitude === locale.finishi.latitude &&
                                                        locale.start.longitude ===
                                                        locale.finishi.longitude ? (
                                                        <>
                                                            <iframe
                                                                src={`https://maps.google.com/maps?q=${locale.finishi.latitude},${locale.finishi.longitude}&t=&z=18&ie=UTF8&iwloc=&output=embed&disableDefaultUI=true&scrollwheel=false&draggable=false`}
                                                                style={{
                                                                    border: 0,
                                                                    width: "100%",
                                                                    height: "250px",
                                                                }}
                                                                title="A Localização inicial é igual a localização final"
                                                                allowFullScreen
                                                            ></iframe>
                                                            <Typography>
                                                                {`A localização inicial é igual a localização final: ${locale.start.latitude} / ${locale.start.longitude}`}
                                                            </Typography>
                                                        </>
                                                    ) : (
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                                gap: "20px",
                                                            }}
                                                        >
                                                            <Box sx={{ width: "100%" }}>
                                                                <iframe
                                                                    src={`https://maps.google.com/maps?q=${locale.start.latitude},${locale.start.longitude}&t=&z=18&ie=UTF8&iwloc=&output=embed&disableDefaultUI=true&scrollwheel=false&draggable=false`}
                                                                    style={{
                                                                        border: 0,
                                                                        width: "100%",
                                                                        height: "200px",
                                                                    }}
                                                                    title="Localização Inicial"
                                                                    allowFullScreen
                                                                ></iframe>
                                                                <Typography>
                                                                    {`Localização Inicial: ${locale.start.latitude} / ${locale.start.longitude}`}
                                                                </Typography>
                                                            </Box>

                                                            <Box sx={{ width: "100%" }}>
                                                                <iframe
                                                                    src={`https://maps.google.com/maps?q=${locale.finishi.latitude},${locale.finishi.longitude}&t=&z=18&ie=UTF8&iwloc=&output=embed&disableDefaultUI=true&scrollwheel=false&draggable=false`}
                                                                    style={{
                                                                        border: 0,
                                                                        width: "100%",
                                                                        height: "200px",
                                                                    }}
                                                                    title="Localização Final"
                                                                    allowFullScreen
                                                                ></iframe>
                                                                <Typography>
                                                                    {`Localização Final: ${locale.finishi.latitude} / ${locale.finishi.longitude}`}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    <br />
                                                    <Typography sx={{ fontWeight: "bold" }}>
                                                        Localização:
                                                    </Typography>
                                                    <Typography>
                                                        Usuário não ativou a localização
                                                    </Typography>
                                                </>
                                            )}
                                        </>
                                    )
                                );
                            })()}

                        <Grid sx={{ marginTop: 2 }}>
                            <Typography variant="h6" gutterBottom textAlign="center">
                                Respostas do Usuário
                            </Typography>

                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 3,
                                    marginTop: 2,
                                }}
                            >
                                {resultSignatory.content.results.map((item) =>
                                    item.combinedItems.map((combinedItem: any) => {
                                        if (combinedItem.type_question === "question") {
                                            const option = optionsFields.find(
                                                (opt) => opt.type === combinedItem.type
                                            )!;
                                            const section =
                                                optionSection.type === combinedItem.type
                                                    ? optionSection
                                                    : null!;

                                            if (option || section) {
                                                return (
                                                    <Grid item xs={12} key={combinedItem.label}>
                                                        <Box>
                                                            {combinedItem.type === "18" ? (
                                                                choicesFields[section.id_choice as any].value(
                                                                    combinedItem
                                                                )
                                                            ) : (
                                                                <>
                                                                    {choicesFields[option.id_choice as any].value(
                                                                        combinedItem
                                                                    )}
                                                                </>
                                                            )}
                                                        </Box>
                                                    </Grid>
                                                );
                                            }
                                        } else if (combinedItem.type_question === "attachment") {
                                            const option = optionsAttachments.find(
                                                (opt) => opt.type === (combinedItem.data ? combinedItem.data.type : combinedItem.type)

                                            );

                                            if (option) {
                                                return (
                                                    <Grid item xs={12} key={(combinedItem.data ? combinedItem.data.label : combinedItem.label)}>
                                                        <Box>
                                                            {option.type === "19" ? (
                                                                <Typography variant="body2">
                                                                    {choicesFields[option.id_choice as any].value(
                                                                        (combinedItem.data ? combinedItem.data : combinedItem)
                                                                    )}
                                                                </Typography>
                                                            ) : (
                                                                <>
                                                                    <Typography variant="body2">
                                                                        {choicesFields[option.id_choice as any].value((combinedItem.data ? combinedItem.data : combinedItem))}
                                                                    </Typography>
                                                                    {combinedItem.data && (
                                                                        <Button
                                                                            sx={{
                                                                                marginTop: 1,
                                                                                backgroundColor: "#FFA890",
                                                                                color: "#F5F5F5",
                                                                                textTransform: "none",
                                                                            }}
                                                                            variant="contained"
                                                                            endIcon={<CloudDownloadIcon />}
                                                                            onClick={() => downloadFileFromS3(combinedItem.file)}
                                                                        >
                                                                            Baixar Arquivo
                                                                        </Button>
                                                                    )}
                                                                </>
                                                            )}
                                                        </Box>
                                                    </Grid>
                                                );
                                            }
                                        }
                                        return null;
                                    })
                                )}
                            </Box>
                        </Grid>

                        {
                            resultSignatory.content.results.some(item =>
                                item.response_validations.some(content => content.checkview_data !== null)
                            ) && (
                                <Grid sx={{ marginTop: 2 }}>
                                    <Typography variant="h6" gutterBottom textAlign="center">
                                        Validações
                                    </Typography>

                                    {resultSignatory.content.results.flatMap((item: ResultItem) =>
                                        item.response_validations.map((content: ResponseValidations) => {
                                            const responseContent = content.checkview_data;

                                            if (responseContent === null) return null;

                                            const nameApi = responseContent.api;
                                            const responseValidations = responseContent.responses.data_json;

                                            return (
                                                <Box key={nameApi}>
                                                    <Divider sx={{ marginY: 2 }}>
                                                        <Chip label={nameApi.toUpperCase()} color="info" />
                                                    </Divider>
                                                    <Box>
                                                        <ul id="ul-report">
                                                            <GenerateList data={responseValidations} />
                                                        </ul>
                                                    </Box>
                                                </Box>
                                            );
                                        })
                                    )}
                                </Grid>
                            )
                        }


                    </Paper>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: 2,
                            padding: 2,
                            backgroundColor: "#F9FAFB",
                        }}
                    >
                        <Button
                            onClick={visualizationPdf}
                            variant="contained"
                            sx={{
                                backgroundColor: "#FFA890",
                                color: "#F5F5F5",
                                textTransform: "none",
                                width: "70%",
                            }}
                            endIcon={<CloudDownloadIcon />}
                        >
                            Baixar PDF
                        </Button>
                        <Button
                            onClick={() => navigate(-1)}
                            sx={{
                                backgroundColor: "#FFFFFF",
                                color: "#000000",
                                border: "1px solid #D1D5DB",
                                width: "70%",
                                textTransform: "none",
                            }}
                        >
                            Voltar
                        </Button>
                    </Box>
                </Grid>
            </Grid >
        </ProtectRoutesOnboarding >
    );
}
