import React from 'react';
import { TypeEditField } from '../../../types/onboarding'
import * as Field from './editField'
import { Modal, Box } from '@mui/material';


const modalStyle = {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '50%',
    left: '80%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    height: '100%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflowY: 'auto',
    p: 4,
};

export default function EditFieldOptions({ open, setOpen, refKey, type }: TypeEditField) {
    
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={modalStyle}>
                {type === 0 && <Field.EditFieldQuestion open={open} setOpen={setOpen} refKey={refKey} />}
                {type === 1 && <Field.EditFieldAttachments open={open} setOpen={setOpen} refKey={refKey} />}
                {type === 2 && <Field.EditFieldAttachments open={open} setOpen={setOpen} refKey={refKey} />}
                {type === 3 && <Field.EditFielDate open={open} setOpen={setOpen} refKey={refKey} />}
                {type === 4 && <Field.EditFieldMultipleOptions open={open} setOpen={setOpen} refKey={refKey} optionType='radio' />}
                {type === 5 && <Field.EditFieldMultipleOptions open={open} setOpen={setOpen} refKey={refKey} optionType='select' />}
                {type === 6 && <Field.EditFieldMultipleOptions open={open} setOpen={setOpen} refKey={refKey} optionType='checkbox' />}
                {type === 7 && <Field.EditFieldSection open={open} setOpen={setOpen} refKey={refKey} />}
            </Box>
        </Modal>
    );
}
