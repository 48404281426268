import React, { useState, useLayoutEffect, MouseEvent, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import * as api from '../../../services/api';
import { useCore } from "../../../hooks/useCore";
import { TypeColumn } from "../../../types/onboarding";

import ModalDelete from "../../../components/ModalDelete";
import { Paginator } from "../../../components/Paginator";

import ProtectRoutesOnboarding from "../index";

import {
    FormControl,
    Input,
    InputAdornment,
    Typography,
    Button,
    Grid,
    MenuItem,
    Menu,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";

import SearchIcon from '@mui/icons-material/Search';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

type TypeData = {
    idOnboarding: string;
    users: string;
    name_model: string;
    date: string;
    actions?: React.ReactNode
}

type ResponseOnboarding = {
    id: string;
    created_at: string;
    name_model: string;
}

type ItemContent = {
    user: {
        email: string;
        first_name: string;
        last_name: string
    }
    content: {
        count: number;
        results: ResponseOnboarding[]
    }
};

type MoreOptionsMenuProps = {
    idOnboarding: string;
}

const columns: TypeColumn[] = [
    { label: 'Usuário', minWidth: 170, align: 'center' },
    { label: 'Nome do Modelo', minWidth: 170, align: 'center' },
    { label: 'Data', minWidth: 170, align: 'center' },
    { label: 'Ações', minWidth: 170, align: 'center' },
];

const qtdResults = 20

export default function OnboardingSaveds() {

    const navigate = useNavigate()

    const { setTitleBar, setPathTitleBar } = useCore()

    const [orderDate, setOrderDate] = useState<boolean>(true)
    const [filterValues, setFilterValues] = useState<string>("")

    const [countOriginalRows, setCountOriginalRows] = useState<number>(0)

    const [page, setPage] = useState<number>(1)

    const [originalRows, setOriginalRows] = useState<TypeData[]>([])

    useLayoutEffect(() => {
        setTitleBar('Onboarding')
        setPathTitleBar('')
    }, [setPathTitleBar, setTitleBar])

    const MoreOptionsMenu = ({ idOnboarding }: MoreOptionsMenuProps) => {
        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
        const [openModal, setOpenModal] = useState(false);

        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const handleOpenModal = () => {
            setOpenModal(true);
        };

        const handleCloseModal = () => {
            setOpenModal(false);
        };

        const editOnboarding = () => {
            navigate(`/onboarding/saved/edit/${idOnboarding}`)
        }

        const duplicatedOnboarding = async () => {
            const responseOnboarding = await api.get(`/onboarding/list/${idOnboarding}/`) as { [key: string]: any }

            let data = responseOnboarding.content

            data = {
                ...data,
                'name_model': data.name_model + ' - Cópia',
                'title_model': data.title_model + ' - Cópia',
                'created_at': '',
                'updated_at': ''
            }

            const response = await api.post(`/onboarding/create/?id_onboarding=${idOnboarding}&duplicated=True`, data) as { [key: string]: any };

            if (response.status === 201) {
                const newOnboarding = response.data;

                const newRow = createData(
                    newOnboarding.content.id,
                    newOnboarding.user.first_name,
                    newOnboarding.content.name_model,
                    newOnboarding.content.created_at.split(' às ')[0]
                );

                setOriginalRows((prevRows) => [newRow, ...prevRows]);

                toast.success('Modelo duplicado com sucesso!!!')
            } else {
                toast.error("Ops.. Tivemos um problema, por favor tente novamente mais tarde!", {
                    position: toast.POSITION.TOP_RIGHT
                })
            }

        }

        const destroyOnboarding = async () => {
            const responseOnboarding = await api.destroy(`/onboarding/destroy/${idOnboarding}/`) as { [key: string]: any };

            if (responseOnboarding.status === 204) {
                handleCloseModal();
                setOriginalRows(prevRows => prevRows.filter(row => row.idOnboarding !== idOnboarding));
                toast.success('Modelo de formulário excluído com sucesso!!!')
            } else {
                toast.error("Ops.. Tivemos um problema, por favor tente novamente mais tarde!", {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
        }

        const menuItems = () => (
            <div>
                <MenuItem onClick={() => { editOnboarding(); handleClose(); }}>
                    <Typography sx={{ display: 'flex', alignItems: 'center', gap: 0.7, cursor: 'pointer' }}>
                        Editar
                    </Typography>
                </MenuItem>
                <MenuItem onClick={() => { duplicatedOnboarding(); handleClose(); }}>
                    <Typography sx={{ display: 'flex', alignItems: 'center', gap: 0.7, cursor: 'pointer' }}>
                        Duplicar
                    </Typography>
                </MenuItem>
                <MenuItem onClick={() => { handleOpenModal(); handleClose(); }}>
                    <Typography sx={{ display: 'flex', alignItems: 'center', gap: 0.7, cursor: 'pointer', color: '#FFA890' }}>
                        Excluir
                    </Typography>
                </MenuItem>
            </div>
        );

        return (
            <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 0.7 }}>
                <IconButton onClick={handleClick}>
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    disableScrollLock={true}
                >
                    {menuItems()}
                </Menu>

                {
                    openModal &&
                    <ModalDelete open={openModal} textBody="Deseja excluir esse modelo de formulário?" handleModalClose={handleCloseModal} handleDelete={destroyOnboarding} loading={false} />
                }
            </Grid>
        );
    };

    const createData = useCallback((
        idOnboarding: string,
        users: string,
        name_model: string,
        date: string
    ): TypeData => {
        const actions = <MoreOptionsMenu idOnboarding={idOnboarding} />;
        return { idOnboarding, users, name_model, date, actions };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            try {

                let url = `/onboarding/list/all/`;

                if (page) {
                    url = `${url}?page=${page}&page_size=${qtdResults}`
                }

                const responseOnboarding = await api.get(url) as ItemContent;

                const rows = responseOnboarding.content.results.map((item: ResponseOnboarding) => {
                    const id_onboarding = item.id
                    const user = responseOnboarding.user.first_name;
                    const name_model = item.name_model;
                    const date = item.created_at.split(' às ')[0];

                    return createData(id_onboarding, user, name_model, date);
                })

                setOriginalRows(rows)
                setCountOriginalRows(responseOnboarding.content.count);

            } catch (error) {
                toast.error('Erro ao buscar dados de onboarding salvos');
            }
        }

        fetchData();
    }, [page, filterValues, createData]);

    const orderDataChange = (e: MouseEvent<HTMLButtonElement>) => {

        setOrderDate(prevState => !prevState);

        const sortedRows = originalRows.slice().sort((a, b) => {
            const partsA = a.date.split('/');
            const dateA = new Date(parseInt(partsA[2], 10), parseInt(partsA[1], 10) - 1, parseInt(partsA[0], 10));

            const partsB = b.date.split('/');
            const dateB = new Date(parseInt(partsB[2], 10), parseInt(partsB[1], 10) - 1, parseInt(partsB[0], 10));

            return orderDate ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
        });

        setOriginalRows(prevState => sortedRows);
    };

    const filterClientsChange = async () => {

        if (filterValues === "") {
            return setOriginalRows(originalRows)
        }

        try {
            const responseOnboarding = await api.get(`/onboarding/list/all/?filter=${filterValues}`) as ItemContent;

            const rows = responseOnboarding.content.results.map((item: ResponseOnboarding) => {
                const id_onboarding = item.id
                const user = responseOnboarding.user.first_name;
                const name_model = item.name_model;
                const date = item.created_at.split(' às ')[0];

                return createData(id_onboarding, user, name_model, date);
            });

            setOriginalRows(state => rows)

        } catch (error) {
            toast.error('Erro ao buscar dados de onboarding e signatários');
        }
    }

    const handleKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {

        if (ev.key === 'Enter') {
            ev.preventDefault();
            filterClientsChange();
        }
    }

    const handleChangePage = (e: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };


    return (
        <ProtectRoutesOnboarding>
            <ToastContainer />

            <Grid
                container
                textAlign="center">

                <Grid
                    item
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        margin: '10px 0',
                        height: '48px',
                        width: '100%',
                        gap: 2
                    }}>

                    <Button
                        onClick={() => navigate('/onboarding')}
                        sx={{
                            padding: 0,
                            minWidth: 'auto',
                            width: '35px',
                            height: '100%',
                            marginRight: 2,
                            '&:hover': {
                                backgroundColor: 'transparent'
                            }
                        }}
                    >
                        <KeyboardArrowLeftIcon
                            sx={{
                                backgroundColor: '#FFFFFF',
                                border: '1px solid #D6D6D6',
                                borderRadius: '12px',
                                padding: '7px',
                                height: '100%',
                                width: '35px',
                                color: 'black'
                            }}
                        />
                    </Button>

                    <FormControl sx={{ flexGrow: 1, height: '100%' }} variant="standard">
                        <Input
                            id="input-search"
                            placeholder="Buscar pelo Nome do Modelo"
                            onKeyDown={handleKeyDown}
                            onChange={(event) => setFilterValues(event.target.value)}
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon sx={{ color: '#D1D5DB', fontSize: '20px', marginLeft: '10px' }} />
                                </InputAdornment>
                            }
                            sx={{
                                '&:before': { borderBottom: 'none' },
                                '&:after': { borderBottom: 'none' },
                                '&:hover:not(.Mui-disabled):before': { borderBottom: 'none' },
                                padding: '10px 0',
                                border: '1px solid #E0E0E0',
                                borderRadius: '4px',
                                backgroundColor: '#FFFFFF',
                                height: '100%',
                                boxShadow: '0 0 3px rgba(0, 0, 0, 0.1)',
                                color: '#9CA3AF'
                            }}
                        />
                    </FormControl>
                    <Button
                        sx={{
                            maxWidth: '100%',
                            height: '100%',
                            backgroundColor: '#FFA890',
                            color: '#FFFFFF',
                            borderRadius: '8px',
                            fontWeight: 'bold',
                            border: 'none',
                            '&:hover': {
                                backgroundColor: '#FFA890',
                                color: 'white',
                                border: 'none'
                            },
                        }}
                        variant="outlined"
                        onClick={filterClientsChange}>Pesquisar
                    </Button>
                </Grid>

            </Grid>

            <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '30px', borderRadius: '15px', boxShadow: '0px 0px 3px 1px rgba(0, 0, 0, 0.2)' }}>
                <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                {columns.map((item, index) => (
                                    <TableCell
                                        key={index}
                                        align={item.align}
                                        sx={{
                                            color: '#6B7280',
                                            backgroundColor: '#F9FAFB',
                                            fontWeight: '600',
                                            borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                            padding: '10px',
                                            textAlign: 'center'
                                        }}
                                    >
                                        {item.label === 'Data' ? (
                                            <Typography onClick={orderDataChange} sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', gap: 0.2, fontWeight: '600' }}>
                                                {item.label} {orderDate ? <ArrowDownwardIcon fontSize='small' /> : <ArrowUpwardIcon fontSize='small' />}
                                            </Typography>
                                        ) : (
                                            item.label
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {originalRows.map((row, rowIndex) => (
                                <TableRow
                                    key={rowIndex}
                                    sx={{
                                        backgroundColor: '#FAFAFA'
                                    }}
                                >
                                    <TableCell align="center" sx={{ backgroundColor: '#FAFAFA' }}>{row.users}</TableCell>
                                    <TableCell align="center" sx={{ backgroundColor: '#FAFAFA' }}>{row.name_model}</TableCell>
                                    <TableCell align="center" sx={{ backgroundColor: '#FAFAFA' }}>{row.date}</TableCell>
                                    <TableCell align="center" sx={{ backgroundColor: '#FAFAFA' }}>{row.actions}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>


            <Grid item sx={{ marginTop: '30px' }}>
                <Paginator count={countOriginalRows} pageNumber={page} qtdResults={qtdResults} onChange={handleChangePage} />
            </Grid>
        </ProtectRoutesOnboarding>
    )
}