import React, { useState } from 'react';
import * as field from './components'
import { useItemsStore } from "../../../contexts/zustand/onboarding"
import { TypeEditField } from '../../../types/onboarding'

import { Modal, Box } from '@mui/material';


const modalStyle = {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '50%',
    left: '80%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    height: '100%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflowY: 'auto',
    p: 4,
};

export function EditFieldQuestion({ open, setOpen, refKey }: TypeEditField) {

    const { items, updateItems } = useItemsStore()

    const [valueLabel, setValueLabel] = useState<string>('');
    const [valueRequired, setValueRequired] = useState<boolean>(false);
    const [valueDescription, setValueDescription] = useState<string>('');

    const handleClose = () => {
        setOpen(false);
    }

    const onSubmitHandle = () => {
        const updatedItem = items.map((item) => {
            if (item.key === refKey) {
                let errorMessage = '';
                errorMessage =  valueLabel !== '' ? '' : 'Adicione um nome';
                return {
                    ...item,
                    label: valueLabel,
                    required: valueRequired,
                    validations: [],
                    description: valueDescription,
                    error: errorMessage
                };
            }
            return item;
        });

        updateItems(updatedItem);
        setOpen(false);
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>

                <field.TextComponentField />
                <field.TextInputField refKey={refKey} valueLabel={valueLabel} setValueLabel={setValueLabel} />
                <field.OptionRequired refKey={refKey} valueRequired={valueRequired} setValueRequired={setValueRequired} />
                <field.TextAreaDescription refKey={refKey} valueDescription={valueDescription} setValueDescription={setValueDescription} />
                <field.OptionsValidations refKey={refKey} />
                <field.ButtonSavedValues setOpen={setOpen} onSubmit={onSubmitHandle} />

            </Box>
        </Modal >
    );
};

export function EditFieldAttachments({ open, setOpen, refKey }: TypeEditField) {

    const { items, updateItems, validations } = useItemsStore()
    const [valueLabel, setValueLabel] = useState<string>('');
    const [valueRequired, setValueRequired] = useState<boolean>(false);
    const [valueDescription, setValueDescription] = useState<string>('');

    const handleClose = () => {
        setOpen(false);
    }

    const onSubmitHandle = () => {
        const updatedItem = items.map((item) => {
            if (item.key === refKey) {
                let errorMessage = '';
                errorMessage =  valueLabel !== '' ? '' : 'Adicione um nome';
                return {
                    ...item,
                    label: valueLabel,
                    required: valueRequired,
                    validations: validations && validations[refKey] ? validations[refKey] : [],
                    description: valueDescription,
                    error: errorMessage
                };
            }
            return item;
        });

        updateItems(updatedItem);
        setOpen(false);
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>

                <field.TextComponentField />
                <field.TextInputField refKey={refKey} valueLabel={valueLabel} setValueLabel={setValueLabel} />
                <field.OptionRequired refKey={refKey} valueRequired={valueRequired} setValueRequired={setValueRequired} />
                <field.TextAreaDescription refKey={refKey} valueDescription={valueDescription} setValueDescription={setValueDescription} />
                {<field.OptionsValidations refKey={refKey} />}
                <field.ButtonSavedValues open={open} setOpen={setOpen} refKey={refKey} onSubmit={onSubmitHandle} />

            </Box>
        </Modal >
    );
};

export function EditFieldMultipleOptions({
    open,
    setOpen,
    refKey,
    optionType
}: TypeEditField & { optionType: 'select' | 'radio' | 'checkbox' }) {

    const { items, updateItems, optionsValues } = useItemsStore()

    const [valueLabel, setValueLabel] = useState<string>('');
    const [valueDescription, setValueDescription] = useState<string>('');
    const [valueRequired, setValueRequired] = useState<boolean>(false);

    const handleClose = () => {
        setOpen(false);
    };
    
    const onSubmitHandle = () => {
        const updatedItem = items.map((item) => {
            if (item.key === refKey) {
                let errorMessage = valueLabel ? '' : 'Adicione um nome';

                const isExcludedType = ['13', '15', '16'].includes(item.type!);

                if (valueLabel && (!optionsValues[refKey] || optionsValues[refKey].length < 2) && isExcludedType) {
                    if (!errorMessage) {
                        errorMessage = 'Adicione pelo menos 2 opções nos campos multi escolha';
                    }
                }

                return {
                    ...item,
                    label: valueLabel,
                    required: valueRequired,
                    validations: [],
                    options: optionsValues[refKey],
                    description: valueDescription,
                    error: errorMessage
                };
            }
            return item;
        });

        updateItems(updatedItem);
        setOpen(false);
    };

    const OptionComponent = {
        select: field.SelectOptions,
        radio: field.RadioOptions,
        checkbox: field.CheckBoxOptions
    }[optionType];

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    ...modalStyle,
                    overflowY: optionsValues[refKey]
                    ? optionsValues[refKey].length >= 1
                        ? 'auto'
                        : 'hidden'
                    : 'auto',
                }}
            >
                <field.TextComponentField />
                <field.TextInputField refKey={refKey} valueLabel={valueLabel} setValueLabel={setValueLabel} />
                <OptionComponent refKey={refKey} />
                <field.OptionRequired refKey={refKey} valueRequired={valueRequired} setValueRequired={setValueRequired} />
                <field.TextAreaDescription refKey={refKey} valueDescription={valueDescription} setValueDescription={setValueDescription} />
                <field.ButtonSavedValues open={open} setOpen={setOpen} refKey={refKey} onSubmit={onSubmitHandle} />
            </Box>
        </Modal>
    );
}

export function EditFielDate({ open, setOpen, refKey }: TypeEditField) {

    const { items, updateItems } = useItemsStore()

    const [valueLabel, setValueLabel] = useState<string>('');
    const [valueDescription, setValueDescription] = useState<string>('');
    const [valueRequired, setValueRequired] = useState<boolean>(false);

    const handleClose = () => {
        setOpen(false);
    }

    const onSubmitHandle = () => {
        const updatedItem = items.map((item) => {
            if (item.key === refKey) {
                let errorMessage = '';
                errorMessage =  valueLabel !== '' ? '' : 'Adicione um nome';
                return {
                    ...item,
                    label: valueLabel,
                    required: valueRequired,
                    validations: [],
                    description: valueDescription,
                    error: errorMessage
                };
            }
            return item;
        });

        updateItems(updatedItem);
        setOpen(false);
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>

                <field.TextComponentField />
                <field.TextInputField refKey={refKey} valueLabel={valueLabel} setValueLabel={setValueLabel} />
                <field.OptionRequired refKey={refKey} valueRequired={valueRequired} setValueRequired={setValueRequired} />
                <field.TextAreaDescription refKey={refKey} valueDescription={valueDescription} setValueDescription={setValueDescription} />
                <field.ButtonSavedValues open={open} setOpen={setOpen} refKey={refKey} onSubmit={onSubmitHandle} />

            </Box>
        </Modal >
    );
};

export function EditFieldSection({ open, setOpen, refKey }: TypeEditField) {

    const { items, updateItems } = useItemsStore()

    const [valueLabel, setValueLabel] = useState<string>('');
    const [valueDescription, setValueDescription] = useState<string>('');

    const handleClose = () => {
        setOpen(false);
    }

    const onSubmitHandle = () => {
        const updatedItem = items.map((item) => {
            if (item.key === refKey) {
                let errorMessage = '';
                errorMessage =  valueLabel !== '' ? '' : 'Adicione um nome';
                return {
                    ...item,
                    label: valueLabel,
                    required: false,
                    validations: [],
                    description: valueDescription,
                    error: errorMessage
                };
            }
            return item;
        });

        updateItems(updatedItem);
        setOpen(false);
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>

                <field.TextComponentField />
                <field.TextInputField refKey={refKey} valueLabel={valueLabel} setValueLabel={setValueLabel} />
                <field.TextAreaDescription refKey={refKey} valueDescription={valueDescription} setValueDescription={setValueDescription} />
                <field.ButtonSavedValues open={open} setOpen={setOpen} refKey={refKey} onSubmit={onSubmitHandle} />

            </Box>
        </Modal >
    );
};


